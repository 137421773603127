
import { defineComponent } from "vue";
import * as fb from "../../firebase";

export default defineComponent({
  name: "register-mmi",
  props: {
    chapter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      registerUsername: "",
    };
  },
  methods: {
    register(username: string) {
      const registerFunc = fb.func.httpsCallable("MMI-registerUser");
      registerFunc({
        chapter: this.chapter,
        username: username,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
