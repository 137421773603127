
import { defineComponent } from "vue";
import { ProfilePermissions } from "@/store/System/SystemConfigInterfaces";

export default defineComponent({
  name: "UserProfilesConfiguration",
  data() {
    return {
      loading: false,
      Profiles: Array<ProfilePermissions>(),
      Users: [{ userPK: "", roles: [""] }],
      currentUser: { userPK: "", roles: [""] },
    };
  },
  created() {
    this.$store
      .dispatch("sys/doGetAllProfiles")
      .then((response) => {
        this.Profiles = response;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$store
      .dispatch("sys/doGetAllUsers")
      .then((response) => {
        this.Users = response;
        this.currentUser.userPK = response[0].userPK;
        this.changeUser(this.currentUser.userPK);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    isChecked(key: string) {
      return this.currentUser.roles.includes(key);
    },
    changeUser(userPK: string) {
      this.currentUser.roles = [""];

      const data = this.Users.find((x) => x.userPK == userPK)?.roles;
      if (data != undefined) {
        this.currentUser.roles = data;
      }

      this.Profiles.forEach((item: any) => {
        const element = document.getElementById(
          item.profileId
        ) as HTMLInputElement;
        element.checked = this.isChecked(item.profileId);
      });
    },
    updatePermissions() {
      this.loading = true;
      const checked = [...document.querySelectorAll("input:checked")].map(
        (e) => e.id
      );
      this.currentUser.roles = checked;

      this.$store
        .dispatch("sys/updateUserProfiles", this.currentUser)
        .then((response) => {
          const user = this.Users.find(
            (x) => x.userPK == this.currentUser.userPK
          );
          if (user != undefined) {
            user.roles = this.currentUser.roles;
          }
          alert(response.message);
        })
        .catch((error) => {
          alert("Error: " + error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
