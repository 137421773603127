
import { defineComponent } from "vue";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import objectSupport from "dayjs/plugin/objectSupport";
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";

interface IClassData {
  title: string;
  active: string | boolean;
  requiredRank: undefined | string;
  scheduleType: string;
  classType: string;
  imageUrl: string;
  position: number;
}

dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);

export default defineComponent({
  name: "classCard",
  data() {
    return {
      day: "",
      hour: "",
      active: "",
      link: "",
      position: 0,
      weekDays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      weekDaysEs: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
      data: {} as IClassData,
      classStatus: "" as string,
      initialDay: "" as string,
      initialHour: "" as string,
    };
  },
  props: {
    classData: { required: true, type: Object },
    classTitle: { required: true, type: String },
    classDate: Object,
    scheduleType: { type: String },
    multiple: { type: Boolean },
    first: { type: Boolean },
    last: { type: Boolean },
    editPermision: { type: Boolean },
    classesPerDay: { required: true, type: Array },
    classIndex: { required: true, type: Number },
  },
  computed: {
    ...mapGetters("auth", ["getUserRole"]),
    modal() {
      return this.$refs.modal ? new Modal(this.$refs.modal) : null;
    },
    modalRemove() {
      return this.$refs.modalRemove ? new Modal(this.$refs.modalRemove) : null;
    },
    btnClass() {
      switch (this.classStatus) {
        case "done":
          return "text-success border";
        case "future":
          return "text-info border";
        case "locked":
          return "text-secondary border";
        case "current":
          return "text-primary border";
        case "inactive":
          return "text-secondary border";
        default:
          return "";
      }
    },
    hours() {
      const hours: Array<string> = [];
      for (let hour = 0; hour < 24; hour++) {
        hours.push(dayjs({ hour }).format("hh:mm A"));
        hours.push(
          dayjs({
            hour,
            minute: 30,
          }).format("hh:mm A")
        );
      }
      return hours;
    },
    classId() {
      return `${this.data.title.toLowerCase().replaceAll(" ", "-")}-${dayjs(
        this.classDate as Date
      ).unix()}`;
    },
    weekDay() {
      const classTime = dayjs(this.classDate as Date);
      const day = classTime.weekday() > 0 ? classTime.weekday() - 1 : 6;
      return this.weekDays[day];
    },
    weekDayEs() {
      const classTime = dayjs(this.classDate as Date);
      const day = classTime.weekday() > 0 ? classTime.weekday() - 1 : 6;
      return this.weekDaysEs[day];
    },
    currentHour() {
      const classTime = dayjs(this.classDate as Date);
      return classTime.format("hh:mm A");
    },
    isActive() {
      if (this.day == "sunday")
        console.log(this.day, this.active, this.classData.active, true);
      return this.active || this.classData.active;
    },
  },
  methods: {
    changePosition(up = true) {
      const weekDay = this.day;
      let position = this.classIndex ?? 0;
      if (up) position = position - 1;
      else position = position + 1;
      if (position <= 0) this.position = 0;

      const _class: IClassData = this.classesPerDay[
        this.classIndex
      ] as IClassData;

      let data = [
        ...(this.classesPerDay.filter(
          (_, ind) => ind != this.classIndex
        ) as Array<IClassData>),
      ].sort((a, b) => a.position - b.position);

      data.splice(position, 0, _class);
      data = data.map((ele, position) => ({ ...ele, position, weekDay }));

      this.$store
        .dispatch("classes/doChangePosition", data)
        .then(() => {
          this.forceUpdate();
        })
        .catch((error) => {
          alert(error);
        });
    },
    // : "done" | "current" | "future" | "locked" | "inactive"
    getClassStatus() {
      const now = dayjs();
      const classTime = dayjs(this.classDate as Date);
      if (this.active === "false" || this.classData.active === false)
        return "inactive";

      if (
        now.isBetween(
          classTime.subtract(15, "minute"),
          classTime.add(1, "hour")
        )
      ) {
        if (
          this.data.requiredRank != undefined &&
          this.data.requiredRank != "INITIAL"
        ) {
          return "locked";
        } else {
          return "current";
        }
      } else if (classTime.isAfter(now)) {
        return "future";
      } else if (classTime.isBefore(now)) {
        return "done";
      }
      return "done";
    },
    setClassStatus() {
      const status = this.getClassStatus();
      if (this.classStatus != status) this.classStatus = status;
    },
    changeStatus(evt) {
      const active = evt.target.checked ? "true" : "false";
      const schedule = this.data.scheduleType;
      const weekDay = this.day;
      const hour = this.hour;
      const link = this.link;
      const type = this.data.classType;
      const imageUrl = this.data.imageUrl;
      const position = this.classIndex;
      const title = this.data.title;

      console.log(this.classIndex);

      this.$store
        .dispatch("classes/doUpdateClass", {
          schedule,
          weekDay,
          hour,
          active,
          link,
          type,
          imageUrl,
          position,
          title,
        })
        .then(() => {
          this.forceUpdate();
        })
        .catch((error) => {
          alert(error);
        });
    },
    openModal() {
      if (this.modal) this.modal.show();
    },
    confirmRemove() {
      if (this.modalRemove) this.modalRemove.show();
    },
    removeClass() {
      const { classType, id: hour } = this.classData;
      const { weekDay: day, scheduleType } = this;
      this.$store
        .dispatch("classes/doRemoveClass", {
          scheduleType,
          classType,
          hour,
          day,
        })
        .then(() => {
          this.forceUpdate();
        })
        .catch((err) => console.log(err));
    },
    saveClass() {
      const schedule = this.classData.scheduleType;
      const weekDay = this.day;
      const hour = this.hour;
      const active =
        typeof this.active == "boolean" ? this.active === "true" : this.active;
      const link = this.link;
      const type = this.classData.classType;
      const position = this.position;
      const imageUrl = this.data.imageUrl;
      const title = this.data.title;
      const initialDay = this.initialDay;
      const initialHour = this.initialHour;

      this.$store
        .dispatch("classes/doUpdateClass", {
          schedule,
          weekDay,
          hour,
          active,
          link,
          type,
          position,
          imageUrl,
          initialDay,
          initialHour,
          title,
        })
        .then(() => {
          this.forceUpdate();
        })
        .catch((error) => {
          alert(error);
        });
    },
    getImage() {
      if (
        (this.data.imageUrl && this.data.imageUrl.startsWith("data:image")) ||
        this.data.imageUrl.search("http") >= 0
      ) {
        return this.data.imageUrl;
      } else {
        const defaultImage = "default-card.png";
        try {
          return require(`../../assets/img/classes/${this.data.imageUrl}`);
        } catch {
          return require(`../../assets/img/classes/${defaultImage}`);
        }
      }
    },
    openClass(url: string) {
      const _url = url + "?s=" + new Date().getTime().toString().substring(4);
      const newWindow = window.open(_url);
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == "undefined"
      ) {
        alert(
          "Favor de permitir las Ventanas Emergentes (Popups) en su navegador"
        );
      }
    },
    forceUpdate() {
      if (this.modal) this.modal.hide();
      if (this.modalRemove) this.modalRemove.hide();
      this.$store.dispatch("classes/doGetSchedules");
    },
  },
  mutations: {
    setStatus(state, status) {
      state.classData.active = status;
    },
  },
  watch: {
    data: function () {
      this.setClassStatus();
    },
    classTime: function () {
      this.setClassStatus();
    },
    active: function () {
      this.setClassStatus();
    },
  },
  mounted() {
    this.setClassStatus();
    this.active = this.isActive.toString();
  },
  beforeMount() {
    this.day = this.weekDay;
    this.initialDay = `${this.weekDay}`;
    this.hour = this.currentHour;
    this.initialHour = `${this.currentHour}`;

    // this.active = this.isActive.toString();
    console.log(this.classData);
    this.link = this.classData.link;
    this.data = this.classData as IClassData;
    this.position =
      this.classData.position == 0 && this.classIndex != 0
        ? this.classIndex
        : this.classData.position ?? 0;
  },
  unmounted() {
    if (this.modal) this.modal.hide();
    if (this.modalRemove) this.modalRemove.hide();
  },
});
