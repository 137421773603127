
import { UserPayout } from "functions/src/System/SystemInterfaces";
import { Commission } from "@/store/Commissions/CommissionsInterfaces";
import { defineComponent, PropType } from "vue";
import * as fb from "../../firebase";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "payout-detail",
  props: {
    payoutDetails: {
      required: true,
      type: Array as PropType<Array<UserPayout>>,
    },
    payoutId: { required: true, type: String },
  },
  data() {
    return {
      priority: "standard",
      checkedUsers: new Array<string>(),
      toggleCheckUsers: false,
      quoting: false,
      paying: false,
      commissionsDetailsMap: new Map<string, Array<Commission>>(),
    };
  },
  methods: {
    wakeFunctions() {
      const quoteWithdrawals = fb.func.httpsCallable(
        "Payments-quoteWithdrawals"
      );
      const processPayouts = fb.func.httpsCallable("Payments-processPayouts");
      const request = {
        withdrawalId: "wake-up",
      };
      quoteWithdrawals(request);
      processPayouts(request);
    },
    toTwoDecimals(num: number): string {
      if (num) {
        return num.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      } else {
        return "0.00";
      }
    },
    toggleCheckboxes() {
      //Fired before value is changed
      this.checkedUsers = []; //Uncheck all users, clean array if all will be added
      if (!this.toggleCheckUsers) {
        //Check all users
        this.sortedPayoutDetails.forEach((u: UserPayout) => {
          this.checkedUsers.push(u.username);
        });
      }
    },
    quoteWithdrawals() {
      this.quoting = true;
      const quoteWithdrawals = fb.func.httpsCallable(
        "Payments-quoteWithdrawals"
      );
      const request = {
        withdrawalId: this.payoutId,
        users: this.checkedUsers,
        priority: this.priority,
      };
      quoteWithdrawals(request)
        .then((response) => {
          if (response.data.OPCODE == "ERROR") {
            console.log(response);
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        })
        .finally(() => {
          this.quoting = false;
        });
    },
    processPayouts() {
      this.paying = true;
      const processPayouts = fb.func.httpsCallable("Payments-processPayouts");
      const request = {
        withdrawalId: this.payoutId,
        users: this.checkedUsers,
        priority: this.priority,
      };
      processPayouts(request)
        .then((response) => {
          if (response.data.OPCODE == "ERROR") {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        })
        .finally(() => {
          this.paying = false;
        });
    },
    statusIconClass(status: string): string {
      switch (status) {
        case "new":
          return "far fa-clock";
        case "invalid_wallet":
          return "fas fa-wallet";
        case "processing":
          return "fas fa-cogs";
        case "paid":
          return "fas fa-flag-checkered";
        case "confirmed":
          return "fas fa-cogs";
        case "complete":
          return "far fa-check-circle";
        default:
          return "";
      }
    },
    statusColorClass(status: string): string {
      switch (status) {
        case "new":
          return "text-warning";
        case "invalid_wallet":
          return "text-danger";
        case "processing":
          return "text-cyan";
        case "paid":
          return "text-teal";
        case "confirmed":
          return "text-cyan";
        case "complete":
          return "text-success";
        default:
          return "";
      }
    },
    translateStatus(status: string): string {
      switch (status) {
        case "new":
          return "Pendiente";
        case "invalid_wallet":
          return "Cartera Inválida";
        case "processing":
          return "En proceso";
        case "paid":
          return "Iniciado";
        case "confirmed":
          return "Procesando";
        case "complete":
          return "Pagado";
        default:
          return status;
      }
    },
    loadCommissionDetails(username: string, commissionsIds: Array<number>) {
      if (!this.commissionsDetailsMap.has(username)) {
        const commissions = new Array<Commission>();
        for (const c of commissionsIds) {
          const commission: Commission = {
            _id: c,
            amount: 0,
            status: "loading",
            source: "",
            date: new Date(c),
            // concept: "",
            type: "",
          };
          commissions.push(commission);
          getCommission(username, c)
            .then((data) => {
              const arrayRef = this.commissionsDetailsMap.get(username);
              if (!arrayRef) {
                return;
              }
              const index = arrayRef.findIndex((c) => c._id == data._id);
              if (index > -1) {
                arrayRef[index].amount = data.amount;
                arrayRef[index].status = data.status;
                arrayRef[index].source = data.source;
                arrayRef[index].type = data.type;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        this.commissionsDetailsMap.set(username, commissions);
      }
    },
    formatDate(milliseconds: number) {
      return dayjs(milliseconds).format("YYYY/MM/DD HH:mm:ss");
    },
    translateType(type: string): string {
      return this.getCommissionTypeConfig(type)?.label || type;
    },
    typeIconClass(type: string): string {
      return this.getCommissionTypeConfig(type)?.icon || "";
    },
    amountClass(status: string): string {
      return this.getCommissionStatusConfig(status)?.amountClass || "";
    },
  },
  computed: {
    ...mapGetters("commissions", [
      "getCommissionTypeConfig",
      "getCommissionStatusConfig",
    ]),
    sortedPayoutDetails() {
      return [...this.payoutDetails].sort((a, b) => b.amount - a.amount);
    },
    totals(): any {
      const totals: any = {
        amount: this.sortedPayoutDetails.reduce(
          (a, b) => a + (b.amount || 0),
          0
        ),
        fee: this.sortedPayoutDetails.reduce((a, b) => a + (b.fee || 0), 0),
        payment: this.sortedPayoutDetails.reduce(
          (a, b) => a + (b.payment || 0),
          0
        ),
        paidAmount: 0,
        blockchainFee: this.sortedPayoutDetails.reduce(
          (a, b) => a + (b.blockchainFee || 0),
          0
        ),
        blockchainFeeUsd: this.sortedPayoutDetails.reduce((a, b) => {
          const _amount: number =
            parseFloat(
              ((b.blockchainFee * b.payment) / b.paidAmount).toFixed(2)
            ) || 0;
          return a + _amount;
        }, 0),
        totalSpent: this.sortedPayoutDetails.reduce(
          (a, b) => a + (b.totalSpent || 0),
          0
        ),
        totalSpentUsd: this.sortedPayoutDetails.reduce((a, b) => {
          const _fee: number =
            parseFloat(
              ((b.blockchainFee * b.payment) / b.paidAmount).toFixed(2)
            ) || 0;
          const _paidAmount: number =
            parseFloat(
              ((b.paidAmount * b.payment) / b.paidAmount).toFixed(2)
            ) || 0;
          return a + _paidAmount + _fee;
        }, 0),
      };
      totals.paidAmount =
        (totals.payment * totals.totalSpent) / totals.totalSpentUsd || 0;
      return totals;
    },
  },
  watch: {
    payoutDetails: function () {
      this.checkedUsers = [];
      this.toggleCheckUsers = false;
      this.quoting = false;
      this.commissionsDetailsMap = new Map<string, Array<Commission>>();
    },
  },
  beforeMount() {
    this.wakeFunctions();
  },
});
export function getCommission(
  username: string,
  commissionId: number
): Promise<any> {
  return fb.fbfs
    .collection("users")
    .doc(username)
    .collection("commissions")
    .doc(new Date(commissionId).toISOString())
    .get()
    .then((doc) => {
      if (doc.data()) {
        const data = doc.data();
        if (data) {
          const response: Commission = {
            _id: commissionId,
            amount: data.amount,
            status: data.status,
            source: data.source,
            date: new Date(commissionId),
            // concept: "",
            type: data.type,
          };
          return response;
        }
      }
    });
}
