
import { defineComponent } from "vue";
import WeekSchedule from "../components/subscriptions/WeekSchedule.vue";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "gtr-system",
  components: { WeekSchedule },
  data() {
    return {
      subscriptionId: "trading-academy",
    };
  },
  computed: {
    ...mapGetters("subs", ["getUserSubscription"]),
    UserStatus(): UserSubscription {
      return this.getUserSubscription(this.subscriptionId);
    },
    isActive(): boolean {
      return this.UserStatus.isActive ? this.UserStatus.isActive : false;
    },
  },
});
