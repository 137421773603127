
import { defineComponent } from "vue";
import router from "@/router";
import * as bs from "bootstrap";
import { nombres } from "@/utils/nombres";
import {
  validationTools,
  EmailValidationResponse,
  UsernameValidationResponse,
  PasswordValidationResponse,
} from "@/mixins/validationTools";

export default defineComponent({
  name: "Register",
  props: ["username", "side"],
  data() {
    return {
      emailTimer: undefined as NodeJS.Timeout | undefined,
      usernameTimer: undefined as NodeJS.Timeout | undefined,
      registrationConfirmModal: {} as any,
      capsLockOn: false,
      showPassword: false,
      registrationForm: {
        email: "",
        username: "",
        password: "",
        passwordConfirm: "",
        enroller: this.username,
        acceptTerms: false,
        picture: "",
      },
      buttonLabel: "Registrarse",
      verifyModalButtonLabel: "Ingresar",
      loading: false,
      emailOK: false,
      usernameOK: false,
      invalidUsername: false,
      usernameAvailable: true,
      usernameValidation: new UsernameValidationResponse(),
      emailValidation: new EmailValidationResponse(),
      passwordValidation: new PasswordValidationResponse(),
      validPassword: true,
      validPasswordConfirm: true,
    };
  },
  mounted() {
    const inputs: HTMLInputElement[] = [];
    inputs.push(this.$refs.email as HTMLInputElement);
    inputs.push(this.$refs.username as HTMLInputElement);
    inputs.push(this.$refs.password as HTMLInputElement);
    inputs.push(this.$refs.confirmPsw as HTMLInputElement);

    inputs.forEach((input: HTMLInputElement) => {
      input.addEventListener("keyup", (event) => {
        this.capsLockOn = event.getModifierState("CapsLock");
      });
    });

    const element: HTMLElement = document.getElementById(
      "registration-conf-modal"
    ) as HTMLElement;
    this.registrationConfirmModal = new bs.Modal(element);

    if (
      !this.registrationForm.enroller ||
      this.registrationForm.enroller === "gtr.academy" ||
      this.registrationForm.enroller === "rootuser" ||
      this.registrationForm.enroller === "rikymillion"
    ) {
      const warningModal = this.$refs.invalidLinkModal as HTMLElement;
      new bs.Modal(warningModal).show();
    }
  },
  methods: {
    generarUsuario() {
      const randomUser =
        nombres[Math.floor(Math.random() * nombres.length) + 1].toLowerCase() +
        ("00" + (Math.floor(Math.random() * 99) + 1)).substr(-2);
      this.registrationForm.email = randomUser + "@email.gtr.academy";
      this.registrationForm.username = randomUser;
      this.registrationForm.password = "a123456789";
      this.registrationForm.passwordConfirm = "a123456789";
      this.registrationForm.acceptTerms = true;
    },
    doRegister() {
      this.loading = true;
      const userData = {
        email: this.registrationForm.email,
        username: this.registrationForm.username,
        password: this.registrationForm.password,
        passwordConfirm: this.registrationForm.passwordConfirm,
        enroller: this.registrationForm.enroller,
        binarySide: this.side,
        pictureURL: "",
      };
      this.$store
        .dispatch("auth/register", userData)
        .then(() => {
          this.$store
            .dispatch("auth/validateEmail", this.registrationForm.email)
            .then((response) => {
              if (response.data.OPCODE == "SUCCESS") {
                this.loading = false;
                this.registrationConfirmModal.show();
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        })
        .catch((error) => {
          alert("Error: " + error.message);
          this.loading = false;
        });
    },
    async login() {
      this.loading = true;
      this.verifyModalButtonLabel = "Ingresando";
      this.buttonLabel = "Ingresando";

      if (
        await this.$store.dispatch("auth/login", {
          userIdentification: this.registrationForm.email,
          password: this.registrationForm.password,
        })
      ) {
        this.registrationConfirmModal.hide();
        router.push({ name: "subscriptions" });
      }
    },
    invalidFields(): boolean {
      return (
        this.invalidUsername ||
        !this.usernameAvailable ||
        !this.validPassword ||
        !this.validPasswordConfirm ||
        !this.registrationForm.enroller ||
        !this.registrationForm.username ||
        !this.registrationForm.email ||
        !this.registrationForm.password ||
        !this.registrationForm.passwordConfirm ||
        !this.registrationForm.acceptTerms ||
        this.registrationForm.password !=
          this.registrationForm.passwordConfirm ||
        this.validateEmail(this.registrationForm.email).hasError
      );
    },
    validateEmailInput() {
      this.emailOK = false;
      this.updateUsername();
      if (this.emailTimer !== undefined) {
        clearTimeout(this.emailTimer);
      }
      if (this.registrationForm.email.length === 0) {
        this.emailValidation = new EmailValidationResponse();
        this.emailValidation.hasError = true;
        this.emailValidation.userMessage = "El correo es requerido";
        return;
      }

      this.emailTimer = setTimeout(async () => {
        this.emailValidation = this.validateEmail(this.registrationForm.email);
        this.validateUsernameInput();
        if (
          this.emailValidation.status === "SUCCESS" ||
          this.emailValidation.status === "WARNING"
        ) {
          if (!(await this.isEmailAvailable())) {
            this.emailValidation.hasWarning = false;
            this.emailValidation.canChange = false;
            this.emailValidation.hasError = true;
            this.emailValidation.userMessage =
              "El correo electrónico ya ha sido registrado.";
          }
          this.emailOK = true;
        }
        clearTimeout(this.emailTimer as NodeJS.Timeout);
        this.emailTimer = undefined;
      }, 500);
    },
    updateUsername() {
      // const prevUsername = this.registrationForm.username;
      const newValue = this.registrationForm.email.split("@")[0];
      // if (
      // prevUsername.length === 0
      // ||
      // (newValue.startsWith(prevUsername) && prevUsername.length !== 0) ||
      // (prevUsername.startsWith(newValue) && (newValue.length !== 0 || prevUsername.length === 1))
      // ) {
      this.registrationForm.username = newValue;
      // }
    },
    async isEmailAvailable(): Promise<boolean> {
      return await this.$store
        .dispatch("auth/GetEmailAvailability", this.registrationForm.email)
        .then((response) => {
          if (response.OPCODE == "UNAVAILABLE") {
            return false;
          } else if (response.OPCODE == "SUCCESS") {
            return true;
          } else {
            throw Error("Can not validate email availability");
          }
        })
        .catch((error) => {
          alert("Error: " + error.message);
          throw error;
        });
    },
    validateUsernameInput() {
      this.invalidUsername = false;
      this.usernameOK = false;

      if (this.usernameTimer !== undefined) {
        clearTimeout(this.usernameTimer);
      }

      if (this.registrationForm.username.length === 0) {
        return;
      }
      this.usernameTimer = setTimeout(async () => {
        this.usernameValidation = await this.validateUsername(
          this.registrationForm.username
        );
        this.invalidUsername = this.usernameValidation.hasError;
        this.usernameAvailable =
          this.usernameValidation.isAvailable !== undefined
            ? this.usernameValidation.isAvailable
            : this.usernameAvailable;
        this.usernameOK = !this.invalidUsername && this.usernameAvailable;
        clearTimeout(this.usernameTimer as NodeJS.Timeout);
        this.usernameTimer = undefined;
      }, 500);
    },
    validatePasswords() {
      this.validatePasswordInput();
      this.validatePasswordConfirm();
    },
    validatePasswordInput() {
      this.passwordValidation = this.validatePassword(
        this.registrationForm.password
      );
      this.validPassword = !this.passwordValidation.hasError;
    },
    validatePasswordConfirm() {
      if (
        this.registrationForm.passwordConfirm.length > 0 &&
        this.registrationForm.passwordConfirm != this.registrationForm.password
      ) {
        this.validPasswordConfirm = false;
        return;
      }
      this.validPasswordConfirm = true;
    },
    gotoLoginPage() {
      this.registrationConfirmModal.hide();
      router.push({ name: "login" });
    },
    refreshPage() {
      this.registrationConfirmModal.hide();
      this.$router.go(0);
    },
  },
  setup() {
    const validateEmail = validationTools.methods.validateEmail;
    const validateUsername = validationTools.methods.validateUsername;
    const validatePassword = validationTools.methods.validPassword;
    return {
      validateEmail,
      validateUsername,
      validatePassword,
    };
  },
});
