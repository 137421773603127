import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0932af45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row full-container" }
const _hoisted_2 = { class: "col-md-7" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event_form = _resolveComponent("event-form")!
  const _component_event_ticket_options = _resolveComponent("event-ticket-options")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentEvent)
        ? (_openBlock(), _createBlock(_component_event_form, {
            key: 0,
            event: _ctx.currentEvent,
            "payment-options": _ctx.currentEvent.payment_options,
            "event-loading": _ctx.eventLoading
          }, null, 8, ["event", "payment-options", "event-loading"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.currentEvent?.ticket_options)
        ? (_openBlock(), _createBlock(_component_event_ticket_options, {
            key: 0,
            event: _ctx.currentEvent,
            "ticket-options": _ctx.currentEvent?.ticket_options
          }, null, 8, ["event", "ticket-options"]))
        : _createCommentVNode("", true)
    ])
  ]))
}