
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  QuoteRequest,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";

export default defineComponent({
  name: "subscription-status-card",
  data() {
    return {
      periodsToAdd: 1,
      QuoteRequest: {} as QuoteRequest<QuoteRequestType>,
    };
  },
  props: {
    subscriptionName: { type: String, required: true, default: "Suscripción" },
    subscriptionIcon: { type: String, required: true, default: "fas fa-book" },
    subscriptionId: { type: Object as () => subscriptionType, required: true },
    lastQuote: { type: Object, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  emits: ["isActive", "clearQuote", "requestQuote"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    ...mapGetters("subs", [
      "getSubscriptionConfig",
      "getUserSubscription",
      "durationUnitLocalized",
    ]),
    ...mapGetters("sys", ["isOffline"]),
    isLoaded(): boolean {
      const loaded =
        this.SubscriptionConfig != undefined && this.UserStatus != undefined;
      return loaded;
    },
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.subscriptionId);
    },
    UserStatus(): UserSubscription {
      return this.getUserSubscription(this.subscriptionId);
    },
    PeriodsStep(): number {
      return this.SubscriptionConfig?.duration || 0;
    },
    DaysLeft(): number {
      // Calculated at subs store
      return this.UserStatus.daysLeft || 0;
    },
    daysLeftClass(): string {
      if (this.DaysLeft <= 5) {
        return "bg-danger";
      } else if (this.DaysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
    isActive(): boolean {
      const status =
        this.UserStatus.endDate != undefined &&
        this.UserStatus.startDate != undefined &&
        this.UserStatus.endDate > new Date();
      this.$emit("isActive", this.subscriptionId, status);
      return status;
    },
    Total(): string {
      const total = this.SubscriptionConfig.cost;
      return total.toLocaleString();
    },
    SubscriptionDurationUnitLocalized(): string {
      return this.durationUnitLocalized(
        this.SubscriptionConfig.duration,
        this.SubscriptionConfig?.durationUnit
      );
    },
    ExpirationDurationUnitLocalized(): string {
      return this.durationUnitLocalized(
        this.DaysLeft,
        this.SubscriptionConfig?.durationUnit
      );
    },
    CanRenewSubscription(): boolean {
      if (this.subscriptionId == "distributor-license") {
        return true;
      } else {
        //Update days at function
        return this.DaysLeft < 3;
      }
    },
  },
  methods: {
    subscriptionQuote() {
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == this.subscriptionId &&
          this.lastQuote.username == this.getUsernamePK &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const request: QuoteSubscriptionRequest = {
          subscriptionId: this.subscriptionId,
          duration: this.SubscriptionConfig.duration,
          durationUnit: this.SubscriptionConfig.durationUnit,
          username: this.getUsernamePK,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
  },
});
