
import { defineComponent } from "vue";
import StatusCard from "./StatusCard.vue";
import { CheckoutModal } from "../modals";
import RegisterCard from "./RegisterCard.vue";
import SearchUser from "../search-users/SearchUser.vue";
import {
  Quote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import { QuoteSubscriptionRequest } from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { quoteSubscription } from "@/store/Subscriptions/SubscriptionsConsts";
import * as bs from "bootstrap";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "DistributorLicense",
  components: { StatusCard, CheckoutModal, RegisterCard, SearchUser },
  data() {
    return {
      quote: {} as Quote<QuoteRequestType>,
      affiliateIsPaid: false,
      isQuoting: false,
      isActive: false,
      modalObj: {} as any,
    };
  },
  computed: {
    ...mapGetters("sys", ["isOffline"]),
  },
  methods: {
    clearQuote() {
      this.quote = {} as Quote<QuoteRequestType>;
    },
    createQuote(request: QuoteSubscriptionRequest): void {
      if (!this.isOffline) {
        this.isQuoting = true;
        this.$store.dispatch("subs/" + quoteSubscription, request).then((q) => {
          if (q.OPCODE == "ERROR") {
            alert(q.message);
          } else {
            this.quote = q;

            if (!this.modalObj._isShown) {
              this.modalObj.show();
            }
          }
          this.isQuoting = false;
        });
      }
    },
    paid() {
      this.affiliateIsPaid = true;
    },
    affiliateMarkedAsPaid() {
      this.affiliateIsPaid = false;
    },
    setIsActive(status: boolean) {
      this.isActive = status;
    },
  },
  mounted() {
    this.modalObj = new bs.Modal(document.getElementById("checkout-modal"));
  },
});
