import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-xxl"
}
const _hoisted_2 = {
  key: 0,
  class: "col-12 col-lg-6 col-xxl-5 d-flex flex-row mb-4"
}
const _hoisted_3 = { class: "col-12 col-xxl-10 offset-xxl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_register_card = _resolveComponent("register-card")!
  const _component_search_user = _resolveComponent("search-user")!
  const _component_checkout_modal = _resolveComponent("checkout-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.isActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_register_card, {
                  lastQuote: _ctx.quote,
                  isPaid: _ctx.affiliateIsPaid,
                  onClearQuote: _ctx.clearQuote,
                  onRequestQuote: _ctx.createQuote,
                  onPaid: _ctx.affiliateMarkedAsPaid
                }, null, 8, ["lastQuote", "isPaid", "onClearQuote", "onRequestQuote", "onPaid"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_search_user, {
              lastQuote: _ctx.quote,
              isPaid: _ctx.affiliateIsPaid,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote,
              onPaid: _ctx.affiliateMarkedAsPaid
            }, null, 8, ["lastQuote", "isPaid", "isQuoting", "onClearQuote", "onRequestQuote", "onPaid"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_checkout_modal, {
      quote: _ctx.quote,
      onClearQuote: _ctx.clearQuote,
      onRenewQuote: _ctx.createQuote,
      onPaid: _ctx.paid
    }, null, 8, ["quote", "onClearQuote", "onRenewQuote", "onPaid"])
  ], 64))
}