import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 col-lg-6 col-xxl-5 d-flex flex-row mb-4"
}
const _hoisted_3 = { class: "col-12 col-lg-6 col-xxl-5 d-flex flex-row mb-4" }
const _hoisted_4 = {
  key: 1,
  class: "col-12 col-lg-6 col-xxl-5 d-flex flex-row mb-4"
}
const _hoisted_5 = { class: "col-12 col-lg-6 col-xxl-5 d-flex flex-row mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gtr_vip = _resolveComponent("gtr-vip")!
  const _component_status_card = _resolveComponent("status-card")!
  const _component_video_payment_card = _resolveComponent("video-payment-card")!
  const _component_checkout_modal = _resolveComponent("checkout-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (
        !_ctx.academySubsData.startDate &&
        !_ctx.isActive.mmiColombia &&
        !_ctx.isActive.academy &&
        _ctx.GtrVipSubscriptionConfig?.active
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_gtr_vip, {
              "subscription-id": "gtr-vip",
              "subscription-name": "GTR VIP",
              "subscription-icon": "fas fa-star text-yellow",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_status_card, {
          "subscription-id": "trading-academy",
          "subscription-name": "Academia Virtual",
          "subscription-icon": "fas fa-chalkboard-teacher text-pink",
          lastQuote: _ctx.quote,
          isQuoting: _ctx.isQuoting,
          onClearQuote: _ctx.clearQuote,
          onRequestQuote: _ctx.createQuote
        }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
      ]),
      (_ctx.isActive.academy || _ctx.isActive.distributor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_status_card, {
              "subscription-id": "distributor-license",
              "subscription-name": "Licencia de Distribuidor",
              "subscription-icon": "fas fa-briefcase text-info",
              lastQuote: _ctx.quote,
              isQuoting: _ctx.isQuoting,
              onClearQuote: _ctx.clearQuote,
              onRequestQuote: _ctx.createQuote
            }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_video_payment_card, {
          "subscription-name": "Master Mind Intensive: Colombia",
          "subscription-icon": "fas fa-fire ",
          "subscription-id": "mmi-colombia",
          lastQuote: _ctx.quote,
          isQuoting: _ctx.isQuoting,
          onClearQuote: _ctx.clearQuote,
          onRequestQuote: _ctx.createQuote
        }, null, 8, ["lastQuote", "isQuoting", "onClearQuote", "onRequestQuote"])
      ])
    ]),
    _createVNode(_component_checkout_modal, {
      quote: _ctx.quote,
      onClearQuote: _ctx.clearQuote,
      onRenewQuote: _ctx.createQuote,
      onPaid: _ctx.paid
    }, null, 8, ["quote", "onClearQuote", "onRenewQuote", "onPaid"])
  ], 64))
}