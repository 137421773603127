
import "dayjs/locale/es";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import EventCountSelector from "@/components/events/EventCountSelector.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "event-card",
  props: ["event", "option", "disabled"],
  components: {
    EventCountSelector,
  },
  data() {
    return {
      today: dayjs(),
    };
  },
  computed: {
    ...mapGetters("events", ["getEvent"]),
    format_currency() {
      return (price) =>
        `${this.event.currency} ` +
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: price.currency,
        }).format(price.value);
    },
    sales_ends() {
      if (this.option.ticket_sales_ends.type == "never") return false;
      if (this.option.ticket_sales_ends.type == "date")
        return dayjs(
          new Date(this.option.ticket_sales_ends.value._seconds * 1000)
        ).locale("es");

      const starts = new Date(this.event.date.start._seconds * 1000);
      let day = dayjs(starts);
      let action = "add";

      switch (this.option.ticket_sales_ends.type) {
        case "after_ends":
        case "before_ends":
          day = dayjs(starts);
          break;
        default:
          day = dayjs(starts);
          break;
      }

      switch (true) {
        case /after/.test(this.option.ticket_sales_ends.type):
          action = "add";
          break;
        case /before/.test(this.option.ticket_sales_ends.type):
          action = "subtract";
          break;
      }

      day = day[action](
        this.option.ticket_sales_ends.value,
        this.option.ticket_sales_ends.unit
      );
      return day.locale("es-mx");
    },
    sales_starts() {
      if (
        !this.option?.ticket_sales_starts ||
        this.option?.ticket_sales_starts?.type == "immediately"
      )
        return dayjs();

      const day = dayjs(
        new Date(this.option.ticket_sales_starts.value.seconds * 1000)
      );
      return day.locale("es");
    },
    sales_started() {
      if (
        !this.option?.ticket_sales_starts ||
        this.option?.ticket_sales_starts?.type == "immediately"
      )
        return true;

      const day = dayjs(
        new Date(this.option.ticket_sales_starts.value.seconds * 1000)
      );
      return this.today >= day;
    },
    sales_ended() {
      const today = dayjs();
      return typeof this.sales_ends === "boolean"
        ? this.sales_ends
        : this.sales_ends < today;
    },
    min() {
      return this.option.tickets_per_order?.minimum_quantity <= 1
        ? 0
        : this.option.tickets_per_order?.minimum_quantity;
    },
    max() {
      return (
        this.option.tickets_per_order?.maximum_quantity ||
        this.option.available_quantity
      );
    },
    show() {
      if (!this.event) return false;
      if (
        this.option.ticket_sales_starts &&
        this.option.ticket_sales_starts?.hide
      )
        return this.today >= this.sales_starts;
      if (this.option.ticket_sales_ends && this.option.ticket_sales_ends?.hide)
        return this.today < this.sales_ends;
      return true;
    },
  },
  watch: {
    sales_started() {
      console.log("sales_started", this.sales_started);
    },
  },
  methods: {
    _changeSelector(value) {
      this.$emit("onChangeSelector", this.option, parseInt(value));
    },
  },
});
