
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import VirtualClassroom from "../components/subscriptions/VirtualClassroom.vue";

export default defineComponent({
  name: "recorded-classes",
  components: {
    VirtualClassroom,
  },
  data() {
    return {
      subscriptionId: "trading-academy",
    };
  },
  computed: {
    ...mapGetters("subs", ["getUserSubscription"]),
    UserStatus(): UserSubscription {
      return this.getUserSubscription(this.subscriptionId);
    },
    isActive(): boolean {
      return this.UserStatus.isActive ? this.UserStatus.isActive : false;
    },
  },
});
