
import { VideoData } from "functions/src/MMI/MMIInterfaces";
import { defineComponent } from "vue";
import VideoCard from "./VideoCard.vue";
import SessionButtonCard from "./SessionButtonCard.vue";

export default defineComponent({
  name: "videos-playlist",
  emits: ["selectedVideo", "watchedVideo", "unwatchedVideo"],
  components: {
    VideoCard,
    SessionButtonCard,
  },
  props: {
    videos: {
      type: Array as () => Array<VideoData>,
      required: true,
    },
    watchedVideos: {
      type: Array as () => Array<string>,
      required: true,
    },
  },
  data() {
    return {
      selectedVideo: -1,
      scrollLeftPosition: 0,
      scrollLeftMax: 0,
    };
  },
  methods: {
    scrollListener() {
      const playlistCont = this.$refs.scroller as HTMLDivElement;
      this.scrollLeftPosition = playlistCont.scrollLeft;
      this.scrollLeftMax = playlistCont.scrollWidth - playlistCont.clientWidth;
    },
    scrollLeft() {
      const playlistCont = this.$refs.scroller as HTMLDivElement;
      playlistCont.scrollBy(-150, 0);
    },
    scrollRight() {
      const playlistCont = this.$refs.scroller as HTMLDivElement;
      playlistCont.scrollBy(150, 0);
    },
    selectVideo(index: number) {
      this.selectedVideo = index;
      this.$emit("selectedVideo", this.videos[index]);
    },
    toggleWatchedVideo(videoId: string) {
      if (this.watchedVideos.includes(videoId)) {
        this.$emit("unwatchedVideo", videoId);
      } else {
        this.$emit("watchedVideo", videoId);
      }
    },
    markAsWatched(index: number) {
      this.$emit("watchedVideo", this.videos[index]);
    },
    markAsUnwatched(index: number) {
      this.$emit("unwatchedVideo", this.videos[index]);
    },
  },
  mounted() {
    this.scrollListener();
  },
});
