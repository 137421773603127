
import { defineComponent } from "vue";
import Modal from "@/components/generals/modal.vue";
import { TicketOptionFormInterface } from "@/store/Events/EventsInterfaces";
import uid from "lodash.uniqueid";
import NewTicketOptionForm from "@/components/events/admin/ticket-options/NewTicketOptionForm.vue";
import { Collapse } from "bootstrap";

export default defineComponent({
  name: "new-ticket-option",
  props: ["option", "options", "event", "paymentOptions"],
  data() {
    return {
      editing: false,
      page: "information",
    };
  },
  components: {
    Modal,
    NewTicketOptionForm,
  },
  computed: {
    uniqueId() {
      return `ticket-option-${uid()}`;
    },
  },
  methods: {
    getEvent() {
      return this.event;
    },
    modifying(editing) {
      console.log(editing);
      this.editing = editing;
    },
    resetData() {
      (this.$refs.ticketOptionForm as TicketOptionFormInterface).resetData();
      this.editing = false;
    },
    saveData() {
      (this.$refs.ticketOptionForm as TicketOptionFormInterface).saveData();
    },
    async changePage(evt) {
      const {
        target: {
          dataset: { bsTarget },
        },
      } = evt;
      const target = bsTarget.replace("#", "");
      this.page = target.replace(`-${this.uniqueId}`, "");

      await Promise.allSettled(
        [...document.querySelectorAll(".collapse")].map((ele) =>
          Collapse.getOrCreateInstance(ele)[
            ele.id == target ? "show" : "hide"
          ]()
        )
      );
    },
  },
  mounted() {
    console.log("options", this.options);

    [...document.querySelectorAll(".collapse")].map((ele) =>
      Collapse.getOrCreateInstance(ele, {
        parent: `#collapse-parent-${this.uniqueId}`,
        toggle: false,
      })
    );
  },
});
