
import { defineComponent } from "vue";
export default defineComponent({
  name: "session-button-card",
  props: ["image", "title", "button", "current", "watched", "index"],
  data() {
    return {
      buttonTypes: {
        scheduled: "Programada",
        live: "En vivo",
        finished: "Finalizada",
      },
    };
  },
});
