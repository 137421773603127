/* eslint-disable @typescript-eslint/no-unused-vars */
import { httpsCallable } from "firebase/functions";
import { images } from "@/mixins/images";
import { ActionContext } from "vuex";
import { auth, functions } from "../../firebase";
import * as fb from "../../firebase";
import {
  CLEAR_USER_PROFILE,
  doGetUserProfile,
  LoginInfo,
  NewUserData,
  SET_USER_PROFILE,
  UserProfile,
  AuthStoreState,
  RankStatus,
  SET_USER_PERMISSIONS,
  CLEAR_USER_PERMISSIONS,
  getUserPermissions,
  sendPasswordChangeEmail,
  SET_EMAIL_STATUS,
  SET_PROFILE_SNAPSHOT_LISTENER,
  CLEAR_PROFILE_SNAPSHOT_LISTENER,
} from "./AuthInterfaces";
import { authCompat } from "../../firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
} from "firebase/auth";

const initialState: AuthStoreState = {
  userProfileData: undefined,
  userPermissions: Array<string>(),
  userVerified: false,
};

const snapshotListeners = {
  profileSnapshopListener: () => {
    return;
  },
};

const state: AuthStoreState = { ...initialState };

const mutations = {
  SET_USER_PROFILE(
    state: { userProfileData: UserProfile },
    profileData: UserProfile
  ) {
    state.userProfileData = profileData;
  },
  CLEAR_USER_PROFILE(state: { userProfileData: Record<string, never> }) {
    state.userProfileData = {};
  },
  SET_USER_PERMISSIONS(
    state: { userPermissions: Array<string> },
    permissions: Array<string>
  ) {
    state.userPermissions = permissions;
  },
  CLEAR_USER_PERMISSIONS(state: { userPermissions: Array<string> }) {
    state.userPermissions = new Array<string>();
  },
  SET_EMAIL_STATUS(state: { userVerified: boolean }, status: boolean) {
    state.userVerified = status;
  },
  CLEAR_PROFILE_SNAPSHOT_LISTENER(state: AuthStoreState) {
    snapshotListeners.profileSnapshopListener();
    snapshotListeners.profileSnapshopListener = () => {
      return;
    };
  },
  SET_PROFILE_SNAPSHOT_LISTENER(state: AuthStoreState, listener: () => void) {
    snapshotListeners.profileSnapshopListener = listener;
  },
};

const actions = {
  async login(
    context: ActionContext<AuthStoreState, any>,
    form: LoginInfo
  ): Promise<boolean> {
    const userId: string = form.userIdentification;
    let email: string = "";
    //Email authentication
    if (!userId.includes("@")) {
      const getEmailByUsername = httpsCallable(functions, "getEmailByUsername");
      email = await getEmailByUsername(userId).then((response: any) => {
        if (response.data.OPCODE == "SUCCESS") {
          return Promise.resolve(response.data.data);
        } else if (response.data.OPCODE == "ERROR") {
          const err = {
            ...response.data.error,
            code: response.data.error.name,
          };
          console.log(err);
          return Promise.reject(err);
        } else {
          return Promise.reject(response.data.error);
        }
      });
    } else {
      email = userId.toLowerCase();
    }
    // sign user in
    return await signInWithEmailAndPassword(auth, email, form.password)
      .then(async (userCredential) => {
        // fetch user profile and set in state
        const loadPromises: Promise<any>[] = [];
        context.commit(SET_EMAIL_STATUS, userCredential.user.emailVerified);
        loadPromises.push(
          context.dispatch(doGetUserProfile, userCredential.user)
        );
        loadPromises.push(
          context.dispatch(getUserPermissions, userCredential.user?.displayName)
        );
        // change route to dashboard
        return await Promise.all(loadPromises).then(() => {
          return Promise.resolve(true);
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
  async register(
    context: ActionContext<AuthStoreState, any>,
    userData: NewUserData
  ) {
    const registerUser = fb.func.httpsCallable("Users-registerUser");
    return registerUser(userData).then((response: any) => {
      if (response.data.OPCODE == "SUCCESS") {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    });
  },
  async doGetUserProfile(
    context: ActionContext<UserProfile, any>,
    user: firebase.default.User
  ) {
    const username = user.displayName || "";
    const userProfileQuery = fb.usersCollection.doc(username);

    await userProfileQuery.get().then(async (querySnapshot) => {
      if (querySnapshot.exists) {
        const userProfile = querySnapshot.data() as UserProfile;
        userProfile.pictureUrl = await images.methods.getProfilePicture(
          userProfile.pictureUrl
        );
        // set user profile in state
        context.commit(SET_USER_PROFILE, userProfile);
      }
    });
    const userProfileListener = userProfileQuery.onSnapshot(async (ss) => {
      const userProfile = ss.data() as UserProfile;
      userProfile.pictureUrl = await images.methods.getProfilePicture(
        userProfile.pictureUrl
      );
      // set user profile in state
      context.commit(SET_USER_PROFILE, userProfile);
    });
    context.commit(SET_PROFILE_SNAPSHOT_LISTENER, userProfileListener);
  },
  async logout(context: ActionContext<AuthStoreState, any>) {
    context.commit(CLEAR_PROFILE_SNAPSHOT_LISTENER);
    context.commit(CLEAR_USER_PROFILE);
    context.commit(CLEAR_USER_PERMISSIONS);
    context.commit("profile/CLEAR_USER_PROFILE_DETAIL", null, { root: true });
    context.commit("subs/CLEAR_USER_SUBS", null, { root: true });
    context.commit("payments/CLEAR_USER_PAYMENTS", null, { root: true });
    context.commit("commissions/CLEAR_LISTENER", null, { root: true });
    context.commit("commissions/CLEAR_USER_COMMISSIONS", null, { root: true });
    context.commit("rankQualification/CLEAR_RANK_QUALIF_DATA", null, {
      root: true,
    });
    context.commit("userNetwork/CLEAR_STATE", null, { root: true });
    context.commit("unilevel/CLEAR_STATE", null, { root: true });
    context.commit("account/CLEAR_STATE", null, { root: true });
    context.dispatch("payouts/unsubscribeListener", null, { root: true });
    context.dispatch("subs/unsubscribeUserSubsListener", null, { root: true });
    localStorage.clear();
    //context.dispatch("commissions/" + unsubscribeListener, "commissions", { root: true });

    //resetState();
    //router.push({ name: "login" });
    await signOut(auth);
  },
  async GetEmailAvailability(
    context: ActionContext<AuthStoreState, any>,
    userEmail: string
  ) {
    const GetEmailAvailability = fb.func.httpsCallable("GetEmailAvailability");
    return GetEmailAvailability(userEmail).then((response: any) => {
      if (
        response.data.OPCODE == "SUCCESS" ||
        response.data.OPCODE == "UNAVAILABLE"
      ) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    });
  },
  async GetUsernameAvailability(
    context: ActionContext<AuthStoreState, any>,
    username: string
  ) {
    const GetUsernameAvailability = fb.func.httpsCallable(
      "GetUsernameAvailability"
    );
    return GetUsernameAvailability(username).then((response: any) => {
      if (
        response.data.OPCODE == "SUCCESS" ||
        response.data.OPCODE == "UNAVAILABLE"
      ) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    });
  },
  updateSetUserProfile(
    context: ActionContext<AuthStoreState, any>,
    userProfile: UserProfile
  ) {
    context.commit(SET_USER_PROFILE, userProfile);
  },
  async validateEmail(
    context: ActionContext<AuthStoreState, any>,
    userData: string
  ) {
    const validateEmail = fb.func.httpsCallable("validateEmail");
    return validateEmail(userData).then((response: any) => {
      if (response.data.OPCODE == "SUCCESS") {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response.data);
      }
    });
  },
  async getUserPermissions(
    context: ActionContext<AuthStoreState, any>,
    userName: string
  ) {
    const getUserPermissions = fb.func.httpsCallable("getUserPermissions");
    return getUserPermissions(userName).then((response: any) => {
      context.commit(SET_USER_PERMISSIONS, response.data.data);
      return Promise.resolve(response);
    });
  },
  async setUserPermissions(
    context: ActionContext<AuthStoreState, any>,
    permissions: any
  ) {
    context.commit(SET_USER_PERMISSIONS, permissions);
  },
  async sendPasswordResetLink(
    context: ActionContext<AuthStoreState, any>,
    userEmail: string
  ) {
    const sendPasswordResetLink = fb.func.httpsCallable(
      "sendPasswordResetLink"
    );
    return sendPasswordResetLink(userEmail).then((response: any) => {
      if (response.data.OPCODE == "SUCCESS") {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response);
      }
    });
  },
  async resetPassword(context: ActionContext<AuthStoreState, any>, data: any) {
    const _token = data.token;
    const _pw = data.pw;

    // Verify the password reset code is valid.
    return authCompat
      .verifyPasswordResetCode(_token)
      .then((email) => {
        // Save the new password.
        return authCompat
          .confirmPasswordReset(_token, _pw)
          .then((resp) => {
            context.dispatch(sendPasswordChangeEmail, email);
            return {
              OPCODE: "SUCCESS",
              message: "Password updated successfully",
            };
          })
          .catch((error) => {
            return {
              OPCODE: "ERROR",
              message: error,
            };
          });
      })
      .catch((error) => {
        return {
          OPCODE: "ERROR",
          message: error.message,
          detail: error,
        };
      });
  },
  async sendPasswordChangeEmail(
    context: ActionContext<AuthStoreState, any>,
    userEmail: string
  ) {
    const sendPasswordChangeEmail = fb.func.httpsCallable(
      "sendPasswordChangeEmail"
    );
    return sendPasswordChangeEmail(userEmail).then((response: any) => {
      return response;
    });
  },
};
const getters = {
  getUserUid(state: any) {
    return state.userProfileData?._uid;
  },
  getUsernamePK(state: any) {
    return state.userProfileData?._usernamePK;
  },
  getUseremail(state: any) {
    return state.userProfileData?.email;
  },
  getUserNickname(state: any) {
    return state.userProfileData?.nickname;
  },
  getUserPicture(state: any) {
    return state.userProfileData?.pictureUrl;
  },
  getUserProfile(state: any) {
    return state.userProfileData;
  },
  getUserVerifiedStatus(state: any) {
    return state.userVerified;
  },
  getUserBinarySide(state: any) {
    return state.userProfileData?.binarySide;
  },
  //Rank related functions
  isRank(state: AuthStoreState) {
    return (rankID: string, status: string): boolean => {
      if (state.userProfileData?.ranksCarrerData) {
        const r = state.userProfileData?.ranksCarrerData.get(rankID);
        return (r || false) && r.status == status;
      } else {
        return false;
      }
    };
  },

  isCurrentRank(state: AuthStoreState, getters: any) {
    return (rankID: string) => {
      return getters.isRank(rankID, "current");
    };
  },

  isNextRank(state: AuthStoreState, getters: any) {
    return (rankID: string) => {
      const r = getters.isRank(rankID, "next");
      return r;
    };
  },

  isRankLocked(state: AuthStoreState, getters: any) {
    return (rankID: string) => {
      return getters.isRank(rankID, "locked");
    };
  },

  isRankUnlocked(state: AuthStoreState, getters: any) {
    return (rankID: string) => {
      return getters.isRank(rankID, "unlocked");
    };
  },

  rankDate(state: AuthStoreState) {
    return (rankID: string) => {
      if (state.userProfileData?.ranksCarrerData) {
        const _rankCarrerData =
          state.userProfileData?.ranksCarrerData.get(rankID);
        return _rankCarrerData?.date;
      }
      return undefined;
    };
  },

  currentRank(state: AuthStoreState): RankStatus | undefined {
    if (state.userProfileData?.ranksCarrerData) {
      let currentRank;
      const ranksCarrerMap = new Map(
        Object.entries(
          state.userProfileData?.ranksCarrerData as Record<string, any>
        )
      ) as Map<string, RankStatus>;
      ranksCarrerMap.forEach((rank: RankStatus) => {
        if (rank.status === "current") {
          currentRank = rank;
          return;
        }
      });
      return currentRank;
    }
    return;
  },

  nextRank(state: AuthStoreState): RankStatus | undefined {
    if (
      state.userProfileData?.ranksCarrerData &&
      state.userProfileData?.ranksCarrerData.forEach
    ) {
      let nextRank;
      state.userProfileData?.ranksCarrerData.forEach((rank: RankStatus) => {
        if (rank.status === "next") {
          nextRank = rank;
          return;
        }
      });
      return nextRank;
    }
    return;
  },
  ranksCarrerDataLoaded(state: AuthStoreState): boolean {
    if (state.userProfileData?.ranksCarrerData) {
      return state.userProfileData?.ranksCarrerData?.size > 0;
    } else {
      return false;
    }
  },
  getRanksCarrerData(state: AuthStoreState): Map<string, RankStatus> {
    if (state.userProfileData?.ranksCarrerData) {
      return state.userProfileData?.ranksCarrerData;
    } else {
      return new Map<string, RankStatus>();
    }
  },
  getUserPermissions(state: any) {
    return state.userPermissions;
  },
  getUserRole(state: any) {
    return state.userProfileData.roles;
  },
};

//const modules= {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
