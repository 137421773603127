/* eslint-disable @typescript-eslint/no-unused-vars */
import * as fb from "../../firebase";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  SubscriptionPromotion,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import {
  SET_PROMOTIONS_CONFIG,
  SET_SUBS_CONFIG,
  SET_USER_SUBS,
  SubscriptionsState,
  UNSET_PROMOTIONS_CONFIG,
  UNSET_SUBS_CONFIG,
  UNSET_USER_SUBS,
} from "./SubscriptionsConsts";
import { ActionContext } from "vuex";
import dayjs from "dayjs";

const initialState: SubscriptionsState = {
  SubscriptionsConfiguration: [],
  UserSubscriptions: [],
  SubscriptionsPromotions: new Array<[string, SubscriptionPromotion]>(),
};

const state = { ...initialState };

const snapshotListeners = {
  userSubscriptions: () => {
    return;
  },
  subscriptionConf: () => {
    return;
  },
  promotionsConf: () => {
    return;
  },
};
const mutations = {
  SET_SUBS_CONFIG(state: SubscriptionsState, subscription: SubscriptionInfo) {
    const index = state.SubscriptionsConfiguration.findIndex((item: any) => {
      return item[0] == subscription.subscriptionId;
    });
    if (index >= 0) {
      state.SubscriptionsConfiguration[index] = [
        subscription.subscriptionId,
        subscription,
      ];
    } else {
      state.SubscriptionsConfiguration.push([
        subscription.subscriptionId,
        subscription,
      ]);
    }
  },
  SET_USER_SUBS(state: SubscriptionsState, subscription: UserSubscription) {
    const index = state.UserSubscriptions.findIndex((item: any) => {
      return item[0] == subscription.subscriptionId;
    });
    if (index >= 0) {
      state.UserSubscriptions[index] = [
        subscription.subscriptionId,
        subscription,
      ];
    } else {
      state.UserSubscriptions.push([subscription.subscriptionId, subscription]);
    }
  },
  UNSET_USER_SUBS(state: SubscriptionsState, subscriptionId: string) {
    state.UserSubscriptions = state.UserSubscriptions.filter((item) => {
      return item[0] != subscriptionId;
    });
  },
  SET_PROMOTIONS_CONFIG(
    state: SubscriptionsState,
    promotion: SubscriptionPromotion
  ) {
    const index = state.SubscriptionsPromotions.findIndex((item: any) => {
      return item[0] == promotion._id;
    });
    if (index >= 0) {
      state.SubscriptionsPromotions[index] = [promotion._id, promotion];
    } else {
      state.SubscriptionsPromotions.push([promotion._id, promotion]);
    }
  },
  CLEAR_USER_SUBS(state: SubscriptionsState) {
    state.UserSubscriptions = [];
  },
  UNSET_SUBS_CONFIG(state: SubscriptionsState, subscriptionId: string) {
    state.SubscriptionsConfiguration = state.SubscriptionsConfiguration.filter(
      (item) => {
        return item[0] != subscriptionId;
      }
    );
  },
  UNSET_PROMOTIONS_CONFIG(state: SubscriptionsState, promotionId: string) {
    state.SubscriptionsPromotions = state.SubscriptionsPromotions.filter(
      (item) => {
        return item[0] != promotionId;
      }
    );
  },
};

const actions = {
  async doGetUserSubscriptions(
    context: ActionContext<SubscriptionsState, any>
  ) {
    const username = context.rootState.auth.userProfileData._usernamePK;
    const _userSubscriptions = fb.fbfs.collection(
      "users/" + username + "/subscriptions/"
    );
    //try {
    const listener = _userSubscriptions.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        if (change.type == "added" || change.type == "modified") {
          const startDate = doc.data().startDate.toDate();
          const endDate = doc.data().endDate.toDate();
          const lastPayDate = doc.data().lastPayDate.toDate();

          const subscription = {
            subscriptionId: doc.id,
            startDate: startDate,
            endDate: endDate,
            lastPayDate: lastPayDate,
            username: username,
          } as UserSubscription;
          if (
            subscription.endDate != undefined &&
            subscription.startDate != undefined
          ) {
            subscription.isActive = subscription.endDate > new Date();
          }
          const now = dayjs();
          const end = dayjs(subscription.endDate);
          subscription.daysLeft = Math.floor(end.diff(now, "day", true));

          context.commit(SET_USER_SUBS, subscription);
        } else if (change.type == "removed") {
          context.commit(UNSET_USER_SUBS, doc.id);
        }
      });
    });
    snapshotListeners.userSubscriptions = listener;
    //} catch (error) {
    //  return await Promise.reject(error);
    //}
  },
  async doGetSubscriptionsConfig(
    context: ActionContext<SubscriptionsState, any>
  ) {
    const _subscriptions = fb.fbfs.collection("subscriptions");
    const listener = _subscriptions.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;

        if (change.type == "added" || change.type == "modified") {
          const subscription = doc.data();
          subscription.subscriptionId = doc.id;
          context.commit(SET_SUBS_CONFIG, subscription);
        } else if (change.type == "removed") {
          context.commit(UNSET_SUBS_CONFIG, doc.id);
        }
      });
    });
    const _promotions = fb.fbfs
      .collectionGroup("promotions")
      .where("status", "==", "active")
      .where("_type", "==", "subscription");
    const promotionsListener = _promotions.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const promotion = doc.data() as SubscriptionPromotion;

        if (change.type == "added" || change.type == "modified") {
          context.commit(SET_PROMOTIONS_CONFIG, promotion);
        } else if (change.type == "removed") {
          context.commit(UNSET_PROMOTIONS_CONFIG, promotion._id);
        }
      });
    });
    snapshotListeners.subscriptionConf = listener;
    snapshotListeners.promotionsConf = promotionsListener;
  },
  async quoteSubscription(
    context: ActionContext<SubscriptionsState, any>,
    request: QuoteSubscriptionRequest
  ) {
    const quoteSubs = fb.func.httpsCallable("Payments-QuoteSubscription");
    return quoteSubs(request)
      .then((r) => {
        if (r.data.OPCODE == "ERROR") {
          return r.data;
        } else {
          // remove 5 minutes from expiration time
          r.data.expirationTime = new Date(
            (r.data.expirationTime as any)._seconds * 1000
          );
          r.data.createdDate = new Date(
            (r.data.createdDate as any)._seconds * 1000
          );
          r.data.updatedDate = new Date(
            (r.data.updatedDate as any)._seconds * 1000
          );
          return r.data;
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw new Error(error);
      });
  },
  unsubscribeUserSubsListener() {
    snapshotListeners.userSubscriptions();
  },
  unsubscribeSubscriptionsConfListener() {
    snapshotListeners.subscriptionConf();
    snapshotListeners.promotionsConf();
  },
};

const getters = {
  getUserSubscriptions(state: SubscriptionsState): Array<UserSubscription> {
    return state.UserSubscriptions;
  },

  getSubscriptionsConfig(state: SubscriptionsState): Array<SubscriptionInfo> {
    return state.SubscriptionsConfiguration;
  },

  isVIP(state: SubscriptionsState, getters: any, rootState: any): boolean {
    const userSubs: Array<Array<any>> = getters.getUserSubscriptions;
    // If user has mmi-colombia subscription, then is VIP
    const isVIP = userSubs.some((item: any) => {
      return item[1].subscriptionId == "mmi-colombia";
    });
    return isVIP;
  },

  getUserSubscription(state: SubscriptionsState, getters: any, rootState: any) {
    return (subscriptionId: string): UserSubscription => {
      const subs =
        (new Map(...[state.UserSubscriptions]).get(
          subscriptionId
        ) as UserSubscription) ||
        ({
          username: rootState.auth.userProfileData?._usernamePK,
          subscriptionId: subscriptionId,
          isActive: false,
        } as UserSubscription);
      return subs;
    };
  },
  getSubscriptionConfig(state: SubscriptionsState) {
    return (subscriptionId: string): SubscriptionInfo | undefined => {
      return new Map(...[state.SubscriptionsConfiguration]).get(
        subscriptionId
      ) as SubscriptionInfo;
    };
  },
  durationUnitLocalized(state: SubscriptionsState) {
    return (duration: number, durationUnit: string): string => {
      let response = "";
      const lang = "es-MX";
      if (lang == "es-MX") {
        switch (durationUnit) {
          case "days":
            switch (duration as number) {
              case 1:
                response = "día";
                break;
              case 0:
              default:
                response = "días";
                break;
            }
            break;
          case "months":
            switch (duration as number) {
              case 1:
                response = "mes";
                break;
              case 0:
              default:
                response = "meses";
                break;
            }
            break;
          case "years":
            switch (duration as number) {
              case 1:
                response = "año";
                break;
              case 0:
              default:
                response = "años";
                break;
            }
            break;
        }
      }
      return response;
    };
  },
  getSubscriptionsPromotions(
    state: SubscriptionsState
  ): Array<SubscriptionPromotion> {
    return state.SubscriptionsPromotions.map((item) => {
      return item[1];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
