
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { tools } from "@/mixins/tools";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default defineComponent({
  name: "subscriptions-card",
  computed: {
    ...mapGetters("subs", ["getUserSubscription"]),
    academySubscription(): UserSubscription {
      return this.getUserSubscription("trading-academy");
    },
    distributorLicenseSubscription(): UserSubscription {
      return this.getUserSubscription("distributor-license");
    },
    academyFormattedEndDate(): string {
      return this.academySubscription.endDate
        ? this.formatDateTimezone(
            (this.academySubscription.endDate as Date).getTime()
          )
        : "-";
    },
    academyDurationUnitLocalized(): string {
      return tools.methods.durationUnitLocalized(
        this.academySubscription.daysLeft || 0,
        "days"
      );
    },
    licenseFormattedEndDate(): string {
      return this.distributorLicenseSubscription.endDate
        ? this.formatDateTimezone(
            (this.distributorLicenseSubscription.endDate as Date).getTime()
          )
        : "-";
    },
    licenseDurationUnitLocalized(): string {
      return tools.methods.durationUnitLocalized(
        this.distributorLicenseSubscription.daysLeft || 0,
        "days"
      );
    },
  },
  methods: {
    formatDateTimezone(milliseconds: number): string {
      return milliseconds == 0
        ? "-"
        : tools.methods.formatDateTimezone(milliseconds);
    },
    daysLeftClass(daysLeft: number): string {
      if (daysLeft <= 5) {
        return "bg-danger";
      } else if (daysLeft <= 10) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    },
  },
});
