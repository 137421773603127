
import { defineComponent } from "vue";
import {
  Timestamp,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore, functions } from "../../../firebase";
import {
  ActivateQuoteRequest,
  Quote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import { formatDateTimezone } from "@/mixins/tools";
import { httpsCallable } from "firebase/functions";
import { BasicResponse } from "functions/src/responseTypes";

export default defineComponent({
  name: "activate-quote",
  props: {
    username: { type: String, required: true },
  },
  emits: ["quoteActivated", "error"],
  data() {
    return {
      update: false,
      quotesList: [] as Quote<QuoteRequestType>[],
      selectedQuote: {
        paymentCurrency: "- - -",
      } as Quote<QuoteRequestType>,
      activating: false,
      quotesListerner: () => {
        return;
      },
      activateQuoteRequest: {} as ActivateQuoteRequest<QuoteRequestType>,
      receivedAmountValidation: {
        isValid: undefined as boolean | undefined,
        hasWaring: false,
        invalidMessage: "" as string | undefined,
        errorCode: undefined as
          | "LESS_THAN_REQUIRED"
          | "MORE_THAN_REQUIRED"
          | "INVALID_AMOUNT"
          | "REQUIRED"
          | undefined,
      },
      validTransactionHashTimer: undefined as NodeJS.Timeout | undefined,
      validTransactionHash: undefined as boolean | undefined,
      validatingTransactionHash: false,
    };
  },
  computed: {
    readOnlyClasses(): any {
      return {
        "bg-light": this.activating,
        "bg-body": !this.activating,
        "bg-gradient": !this.activating,
        "text-secondary": !this.activating,
      };
    },
    isValid(): boolean {
      return (
        this.selectedQuote.tx_id != undefined &&
        this.activateQuoteRequest.paymentAmount != undefined &&
        parseFloat(this.activateQuoteRequest.paymentAmount) > 0 &&
        this.validTransactionHash === true &&
        this.receivedAmountValidation.isValid === true
      );
    },
    canEditFields(): boolean {
      return (
        this.update === true &&
        this.selectedQuote.address !== undefined &&
        this.validatingTransactionHash === false &&
        this.activating === false
      );
    },
    isDate24HoursOld(): boolean {
      if (this.selectedQuote.createdDate == undefined) return false;
      const now = new Date();
      const createdDate = this.selectedQuote.createdDate as Date;
      const diff = now.getTime() - createdDate.getTime();
      return diff > 24 * 60 * 60 * 1000;
    },
    receivedAmountClasses(): any {
      return {
        "is-valid":
          this.receivedAmountValidation.isValid === true &&
          !this.receivedAmountValidation.hasWaring,
        "is-invalid":
          this.receivedAmountValidation.isValid === false &&
          (this.receivedAmountValidation.errorCode === "INVALID_AMOUNT" ||
            this.receivedAmountValidation.errorCode === "REQUIRED"),
        "has-warning":
          this.receivedAmountValidation.hasWaring === true &&
          (this.receivedAmountValidation.errorCode === "MORE_THAN_REQUIRED" ||
            this.receivedAmountValidation.errorCode === "LESS_THAN_REQUIRED"),
      };
    },
    txHashClasses(): any {
      return {
        "is-valid": this.validTransactionHash === true,
        "is-invalid": this.validTransactionHash === false,
        "is-validating": this.validatingTransactionHash === true,
      };
    },
  },
  methods: {
    selectQuote(quote: Quote<QuoteRequestType>) {
      // this.activateQuoteRequest = {} as ActivateQuoteRequest<QuoteRequestType>;
      this.selectedQuote = quote;
      // Reset activateQuoteRequest
      this.activateQuoteRequest = {} as ActivateQuoteRequest<QuoteRequestType>;
      this.activateQuoteRequest = {
        ...this.activateQuoteRequest,
        ...this.selectedQuote,
      };
      this.activateQuoteRequest.receivedCurrency =
        this.selectedQuote.paymentCurrency;
      this.activateQuoteRequest.quoteLabel = this.selectedQuote.tx_id;
      this.validateAmount();
    },
    activateQuote() {
      this.activating = true;
      const activateQuote = httpsCallable<
        ActivateQuoteRequest<QuoteRequestType>,
        BasicResponse
      >(functions, "Admin-ActivateQuote");
      activateQuote(this.activateQuoteRequest)
        .then((r) => {
          const response = r.data;
          if (response.OPCODE == "SUCCESS") {
            this.$emit("quoteActivated");
            // Clear fields
            this.selectQuote({
              paymentCurrency: "- - -",
            } as Quote<QuoteRequestType>);
            this.activateQuoteRequest =
              {} as ActivateQuoteRequest<QuoteRequestType>;
            this.resetAmountValidation();
            this.validTransactionHash = undefined;
          } else if (response.OPCODE == "ERROR") {
            this.$emit(
              "error",
              "Error al Activar Ficha de Pago",
              response.message + "\n" + (response.messageDetails || "")
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          alert(error);
        })
        .finally(() => {
          this.activating = false;
        });
    },
    formatDateTimezone(date: Date) {
      if (date == undefined) return "";
      return formatDateTimezone(date.getTime());
    },
    validateTransactionHash() {
      this.validTransactionHash = undefined;
      if (this.validTransactionHashTimer !== undefined) {
        clearTimeout(this.validTransactionHashTimer);
      }
      if (this.activateQuoteRequest.txHashId.length === 0) {
        return;
      }

      this.validTransactionHashTimer = setTimeout(async () => {
        const isValidTransactionHash = httpsCallable(
          functions,
          "Payments-isValidTransaction"
        );
        this.validatingTransactionHash = true;
        this.validTransactionHash = await isValidTransactionHash(
          this.activateQuoteRequest.txHashId
        )
          .then((response) => {
            return response.data as boolean;
          })
          .catch((error) => {
            console.log("error", error);
            alert(error);
            return false;
          })
          .finally(() => {
            this.validatingTransactionHash = false;
          });
      }, 500);
      return;
    },
    validateAmount() {
      this.receivedAmountValidation.isValid = undefined;
      this.receivedAmountValidation.hasWaring = false;
      this.receivedAmountValidation.invalidMessage = "";

      console.log(this.activateQuoteRequest.receivedAmount);
      if (this.activateQuoteRequest.receivedAmount == undefined) return;
      // If is an empty string, return is required
      if (this.activateQuoteRequest.receivedAmount.length === 0) {
        this.receivedAmountValidation.isValid = false;
        this.receivedAmountValidation.errorCode = "REQUIRED";
        this.receivedAmountValidation.invalidMessage =
          "El monto enviado es requerido!";
        return;
      }
      // Verify the amount format to be a float, max 8 digits after the decimal point
      console.log(
        /^[0-9]+([.][0-9]{1,8})?$/.test(
          this.activateQuoteRequest.receivedAmount
        )
      );
      if (
        !/^[0-9]+([.][0-9]{1,8})?$/.test(
          this.activateQuoteRequest.receivedAmount
        )
      ) {
        this.receivedAmountValidation.isValid = false;
        this.receivedAmountValidation.errorCode = "INVALID_AMOUNT";
        this.receivedAmountValidation.invalidMessage =
          "El formato del monto envíado es INVALIDO!";
        return;
      }
      const amountReceived = parseFloat(
        this.activateQuoteRequest.receivedAmount
      );
      if (isNaN(amountReceived)) {
        this.receivedAmountValidation.isValid = false;
        this.receivedAmountValidation.invalidMessage =
          "El monto enviado NO es un número!";
        return;
      }
      const paymentAmount = parseFloat(this.selectedQuote.paymentAmount);
      if (isNaN(paymentAmount)) {
        this.receivedAmountValidation.isValid = false;
        this.receivedAmountValidation.errorCode = "INVALID_AMOUNT";
        this.receivedAmountValidation.invalidMessage =
          "Favor de seleccionar una Ficha de Pago!";
        return;
      }
      // If is 0, return is required
      if (amountReceived === 0) {
        this.receivedAmountValidation.isValid = false;
        this.receivedAmountValidation.errorCode = "REQUIRED";
        this.receivedAmountValidation.invalidMessage =
          "El monto enviado NO puede ser Cero!";
        return;
      }
      if (amountReceived < paymentAmount) {
        this.receivedAmountValidation.isValid = true;
        this.receivedAmountValidation.hasWaring = true;
        this.receivedAmountValidation.errorCode = "LESS_THAN_REQUIRED";
        this.receivedAmountValidation.invalidMessage =
          "El monto enviado es MENOR al requerido!";
        return;
      }
      if (amountReceived > paymentAmount) {
        this.receivedAmountValidation.isValid = true;
        this.receivedAmountValidation.hasWaring = true;
        this.receivedAmountValidation.errorCode = "MORE_THAN_REQUIRED";
        this.receivedAmountValidation.invalidMessage =
          "El monto enviado es MAYOR al requerido!";
        return;
      }
      this.receivedAmountValidation.isValid = amountReceived == paymentAmount;
    },
    resetAmountValidation() {
      this.receivedAmountValidation.isValid = undefined;
      this.receivedAmountValidation.hasWaring = false;
      this.receivedAmountValidation.invalidMessage = "";
    },
  },
  mounted() {
    const quotesRef = collection(firestore, `users/${this.username}/quotes`);
    // Get the list of active quotes and add it to the list
    const activeQuotes = query(
      quotesRef,
      where("subscriptionActivated", "==", false),
      where(
        "createdDate",
        ">=",
        Timestamp.fromDate(new Date(Date.now() - 48 * 60 * 60 * 1000))
      ),
      orderBy("createdDate", "desc")
    );
    this.quotesListerner = onSnapshot(activeQuotes, (snapshot) => {
      this.quotesList = [];
      snapshot.forEach((doc) => {
        const quote = doc.data() as Quote<QuoteRequestType>;
        quote.createdDate = (quote.createdDate as Timestamp).toDate();
        this.quotesList.push(quote);
      });
    });
  },
  unmounted() {
    this.quotesListerner();
  },
});
