
import { defineComponent } from "vue";
import StatusCard from "./StatusCard.vue";
import WeekSchedule from "./WeekSchedule.vue";
import { CheckoutModal } from "../modals";
import {
  Quote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import { QuoteSubscriptionRequest } from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { quoteSubscription } from "@/store/Subscriptions/SubscriptionsConsts";
import { mapActions, mapGetters } from "vuex";
import * as fb from "../../firebase";
import * as bs from "bootstrap";

export default defineComponent({
  name: "TradingAcademy",
  components: { StatusCard, WeekSchedule, CheckoutModal },
  data() {
    return {
      isLoaded: false,
      isActive: false,
      isQuoting: false,
      quote: {} as Quote<QuoteRequestType>,
      modalObj: {} as any,
      popovers: [] as any,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("sys", ["isOffline"]),
    ...mapGetters("profile", { weknowData: "getUserWeknowData" }),
    isClassroomActive(): boolean {
      return this.weknowData ? this.weknowData.status === "active" : false;
    },
  },
  methods: {
    ...mapActions("profile", ["doGetUserWeknowData"]),
    clearQuote() {
      this.quote = {} as Quote<QuoteRequestType>;
    },
    createQuote(request: QuoteSubscriptionRequest) {
      if (!this.isOffline) {
        this.isQuoting = true;
        this.$store.dispatch("subs/" + quoteSubscription, request).then((q) => {
          if (q.OPCODE == "ERROR") {
            alert(q.message);
          } else {
            this.quote = q;

            if (!this.modalObj._isShown) {
              this.modalObj.show();
            }
          }
          this.isQuoting = false;
        });
      }
    },
    setIsActive(status: boolean) {
      this.isActive = status;
    },
    classroomLogin() {
      const classroomInfoRequest = {
        username: this.getUsernamePK,
      };

      const GetClassroomInfo = fb.func.httpsCallable("Users-getClassroomInfo");
      GetClassroomInfo(classroomInfoRequest).then((response) => {
        const info = response.data;
        if (info.OPCODE == "SUCCESS") {
          (this.$refs.username as HTMLInputElement).value = info.data.username;
          (this.$refs.password as HTMLInputElement).value = info.data.password;
          (this.$refs.formClassroom as HTMLFormElement).action =
            info.data.url + "login/index.php";
          (this.$refs.btnSubmit as HTMLButtonElement).click();
          (this.$refs.username as HTMLInputElement).value = "";
          (this.$refs.password as HTMLInputElement).value = "";
        } else {
          alert(
            "No se pudo obtener la información para acceder a las clases grabadas."
          );
        }
      });
    },
  },
  mounted() {
    this.modalObj = new bs.Modal(document.getElementById("checkout-modal"));
    //this.$nextTick(() => {
    new bs.Popover(this.$refs.popups);
    new bs.Popover(this.$refs.activando);

    // const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    // console.log("list: ", popoverTriggerList);
    // this.popovers = popoverTriggerList.map(function(popoverTriggerEl) {
    //   console.log(popoverTriggerEl);
    //   return new bs.Popover(popoverTriggerEl);
    // });
    //});
    this.doGetUserWeknowData(this.getUsernamePK);
  },
});
