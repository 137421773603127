
import "dayjs/locale/es";
import Modal from "../../../../components/generals/modal.vue";
import { defineComponent } from "vue";
import uid from "lodash.uniqueid";
import {
  TicketPaymentOptions as ITicketPaymentOptions,
  PaymentOptions as IPaymentOptions,
} from "../../../../store/Events/EventsInterfaces";
import VueDatePicker from "@vuepic/vue-datepicker";
import _ from "lodash";
import dayjs from "dayjs";

export default defineComponent({
  name: "payment-option-modal",
  emits: ["savePaymentOptions", "onSetPaymentOptions"],
  props: ["option", "id", "buttonClass", "newEvent", "eventId"],
  data() {
    return {
      option_types: {
        day_next_month: "Día del siguiente mes de la compra.",
        days_after_purchase: "Días despúes de la compra.",
        specific_date: "Fecha especifica",
        immediately: "Inmediatamente",
      },
      currentData: {} as ITicketPaymentOptions,
      optionData: {} as ITicketPaymentOptions,
      enable_start: false,
      enable_end: false,
      currentEventId: "" as string,
      loading: false,
    };
  },
  components: {
    Modal,
    VueDatePicker,
  },
  computed: {
    uniqueId() {
      return `payment-option-modal-${this.id ?? uid()}`;
    },
    editing() {
      return Object.entries(this.currentData).length > 0;
    },
    getOptions() {
      return (ind) => {
        const options = this.currentData?.options ?? this.optionData?.options;
        if (!options) return null;
        return options[ind];
      };
    },
    getOptionDate() {
      return (date) => {
        if (typeof date == "number") return null;
        if (date.constructor.name == "Date") return date;
        return new Date(date);
      };
    },
  },
  watch: {
    currentData: function (currentData) {
      if (currentData.option) delete currentData.option;
    },
    option: function () {
      this.processData();
    },
    eventId: function (eventId) {
      this.currentEventId = eventId;
    },
  },
  methods: {
    saveData() {
      const data = { ...this.currentData, id: this.id };
      let percentage = (data.options as any).reduce(
        (a: any, b: any) => Number(a.percentage) + Number(b.percentage)
      );

      if (typeof percentage == "object")
        percentage = Number(percentage.percentage);

      if (percentage < 100 || percentage > 100)
        return alert(
          `El porcentage total debe ser igual a 100%. Obtenido: ${percentage}%`
        );

      this.loading = true;
      let parent: any = this.$parent?.$parent as any;
      console.log("parent", parent);
      if (!parent.getData) parent = parent?.$parent;
      this.$store
        .dispatch("events/createPaymentOption", {
          eventId: this.eventId,
          data,
        })
        .then(() => {
          (this.$refs.modal as any).dismiss();
          parent.getData();
          this.resetData();
          this.loading = false;
          this.addDiscount();
        })
        .catch((err) => console.log(err));
    },
    resetData() {
      this.currentData = {} as ITicketPaymentOptions;
    },
    modifyValue(evt) {
      const {
        target: { name, value },
      } = evt;

      if (name.search(/options?-/) >= 0) {
        const [ele, index, key] = name.split("-");
        const options = (
          this.currentData?.options ?? this.optionData.options
        ).map((ele, ind) => {
          if (key == "type" && value == "immediately") ele.percentage = 100;
          // else ele.percentage = 0;

          if (ind != index) return ele;
          console.log(key);
          ele[key] = value;
          return ele;
        });

        this.currentData = {
          ...this.currentData,
          [ele]: options,
        };
      } else {
        this.currentData = {
          ...this.currentData,
          [name]: value,
        };
      }
    },
    formatDate(date) {
      return dayjs(date).format("DD MMMM, YYYY • hh:mm a");
    },
    addDiscount() {
      const options =
        this.currentData?.options ?? this.optionData?.options ?? [];
      this.currentData = {
        ...this.currentData,
        options: [
          ...options,
          {
            type: "day_next_month",
            percentage: 0,
            value: 0,
            position: options?.length ?? 0,
          },
        ] as IPaymentOptions[],
      };
    },
    removeDiscount(evt) {
      let target = evt.target;
      if (target.tagName == "I") target = target.parentNode;
      const ind = target.name;
      const options = (
        this.currentData?.options ?? this.optionData.options
      ).filter((ele, idx) => idx != ind);
      this.currentData = {
        ...this.currentData,
        options,
      };
    },
    processData() {
      if (this.eventId) this.currentEventId = this.eventId;

      if (this.option) {
        this.optionData = _.cloneDeep({
          ...this.option,
          start: this.option.start
            ? (new Date(this.option.start.seconds * 1000) as Date)
            : null,
          end: this.option.end
            ? (new Date(this.option.end.seconds * 1000) as Date)
            : null,
        });

        this.enable_start = !!this.option.start;
        this.enable_end = !!this.option.end;
      }
    },
  },
  mounted() {
    if (!this.option) this.addDiscount();
    this.processData();
    if (this.eventId) this.currentEventId = this.eventId;
  },
});
