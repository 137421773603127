import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 col-lg-6 col-xxl-5 d-flex flex-row mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_virtual_classroom = _resolveComponent("virtual-classroom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_virtual_classroom, { "show-live-classes": false })
        ]))
      : _createCommentVNode("", true)
  ]))
}