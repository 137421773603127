
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as bs from "bootstrap";
import {
  PasswordValidationResponse,
  validationTools,
} from "@/mixins/validationTools";

export default defineComponent({
  name: "change-password-modal",
  emits: ["passwordChanged"],
  data() {
    return {
      modalObject: {} as any,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordValidation: new PasswordValidationResponse(),
      errors: Array<string>(),
      invalidOldPasswordMessage: "",
      invalidNewPasswordMessage: "",
      invalidConfirmPasswordMessage: "",
      formErrorMessage: "",
      saving: false,
      showPassword: false,
      capsLockOn: false,
    };
  },
  computed: {
    isValid(): boolean {
      return (
        this.oldPassword.length > 5 &&
        this.newPassword.length > 5 &&
        this.newPassword === this.confirmPassword
      );
    },
    username(): string {
      return firebase.auth().currentUser?.displayName || "";
    },
    userEmail(): string {
      return firebase.auth().currentUser?.email || "";
    },
  },
  methods: {
    typeOldPassword() {
      this.errors = this.errors.filter((error) => error !== "oldPassword");
      this.invalidOldPasswordMessage = "";
    },
    validateConfirmPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.errors.push("confirmPassword");
        this.invalidConfirmPasswordMessage = "Las contraseñas no coinciden";
      } else {
        this.errors = this.errors.filter(
          (error) => error !== "confirmPassword"
        );
        this.invalidConfirmPasswordMessage = "";
      }
    },
    validateNewPassword() {
      this.passwordValidation = this.validatePassword(this.newPassword);
      if (this.passwordValidation.hasError) {
        this.errors.push("newPassword");
        this.invalidNewPasswordMessage = this.passwordValidation.userMessage;
      } else {
        this.errors = this.errors.filter((error) => error !== "newPassword");
        this.invalidNewPasswordMessage = "";
      }
    },
    changePassword() {
      this.saving = true;
      this.errors = [];
      const userEmail = firebase.auth().currentUser?.email || "";
      const credential = firebase.auth.EmailAuthProvider.credential(
        userEmail,
        this.oldPassword
      );
      firebase
        .auth()
        .currentUser?.reauthenticateWithCredential(credential)
        .then(() => {
          return firebase
            .auth()
            .currentUser?.updatePassword(this.newPassword)
            .then(() => {
              this.$emit(
                "passwordChanged",
                "Cambio de contrasña exitoso",
                "La contraseña ha sido cambiada de manera exitosa."
              );
              this.oldPassword = "";
              this.newPassword = "";
              this.confirmPassword = "";
              this.closeModal();
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        })
        .catch((e) => {
          const error = new Error();
          if (e.code) {
            switch (e.code) {
              case "auth/wrong-password":
                error.message = "La contraseña anterior es incorrecta.";
                this.invalidOldPasswordMessage = error.message;
                this.errors.push("oldPassword");
                this.oldPassword = "";
                break;
              case "auth/weak-password":
                error.message = "La contraseña es muy débil.";
                this.invalidNewPasswordMessage = error.message;
                this.errors.push("newPassword");
                break;
              case "auth/invalid-password":
                error.message = "La contraseña no es válida.";
                this.invalidNewPasswordMessage = error.message;
                this.errors.push("newPassword");
                break;
              case "auth/user-mismatch":
                error.message =
                  "El usuario es incorrecto o no coincide con el usuario autenticado.";
                this.formErrorMessage = error.message;
                this.errors.push("formError");
                break;
              default:
                error.message = "Error al cambiar la contraseña.\n" + e.message;
                this.formErrorMessage = error.message;
                this.errors.push("formError");
                break;
            }
          } else {
            error.message = "Error al cambiar la contraseña.\n" + e.message;
            this.formErrorMessage = error.message;
            this.errors.push("formError");
          }
          console.log(e);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    closeModal() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.errors = [];
      this.invalidOldPasswordMessage = "";
      this.invalidNewPasswordMessage = "";
      this.invalidConfirmPasswordMessage = "";
      this.formErrorMessage = "";
      this.modalObject.hide();
    },
  },
  mounted() {
    this.modalObject = bs.Modal.getOrCreateInstance(
      document.getElementById("change-password-modal")
    );
    const inputs: HTMLInputElement[] = [];
    inputs.push(this.$refs.password as HTMLInputElement);
    inputs.push(this.$refs.newPassword as HTMLInputElement);
    inputs.push(this.$refs.confirmPassword as HTMLInputElement);

    inputs.forEach((input: HTMLInputElement) => {
      input.addEventListener("keyup", (event) => {
        this.capsLockOn = event.getModifierState("CapsLock");
      });
    });
  },
  setup() {
    const validatePassword = validationTools.methods.validPassword;
    return {
      validatePassword,
    };
  },
});
