
import { defineComponent } from "vue";
import StatusCard from "./StatusCard.vue";
import GtrVip from "./GtrVip.vue";
import VideoPaymentCard from "./VideoPaymentCard.vue";
import { CheckoutModal } from "../modals";
import {
  Quote,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { quoteSubscription } from "@/store/Subscriptions/SubscriptionsConsts";
import { mapGetters } from "vuex";
import * as bs from "bootstrap";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";

export default defineComponent({
  name: "subscriptions-panel",
  components: {
    StatusCard,
    CheckoutModal,
    VideoPaymentCard,
    GtrVip,
  },
  data() {
    return {
      affiliateIsPaid: false,
      isQuoting: false,
      quote: {} as Quote<QuoteRequestType>,
      modalObj: {} as any,
      popovers: [] as any,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsernamePK"]),
    ...mapGetters("subs", ["getUserSubscription", "getSubscriptionConfig"]),
    ...mapGetters("sys", ["isOffline"]),
    academySubsData(): UserSubscription {
      return this.getUserSubscription("trading-academy");
    },
    licenseSubsData(): UserSubscription {
      return this.getUserSubscription("distributor-license");
    },
    academyIsActive(): boolean {
      return this.academySubsData.isActive
        ? this.academySubsData.isActive
        : false;
    },
    licenseIsActive(): boolean {
      return this.licenseSubsData.isActive
        ? this.licenseSubsData.isActive
        : false;
    },
    isActive(): {
      academy: boolean;
      distributor: boolean;
      mmiColombia: boolean;
    } {
      return {
        academy: this.academyIsActive,
        distributor: this.licenseIsActive,
        mmiColombia: false, //this.licenseIsActive
      };
    },
    GtrVipSubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig("gtr-vip");
    },
  },
  methods: {
    clearQuote() {
      this.quote = {} as Quote<QuoteRequestType>;
    },
    createQuote(request: QuoteSubscriptionRequest) {
      if (!this.isOffline) {
        this.isQuoting = true;
        this.$store.dispatch("subs/" + quoteSubscription, request).then((q) => {
          if (q.OPCODE == "ERROR") {
            alert(q.message);
          } else {
            this.quote = q;

            if (!this.modalObj._isShown) {
              this.modalObj.show();
            }
          }
          this.isQuoting = false;
        });
      }
    },
    paid() {
      this.affiliateIsPaid = true;
    },
    affiliateMarkedAsPaid() {
      this.affiliateIsPaid = false;
    },
  },
  mounted() {
    this.modalObj = new bs.Modal(document.getElementById("checkout-modal"));
  },
});
