
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ClassCard from "./ClassCard.vue";
import dayjs from "dayjs";
import IsoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import objectSupport from "dayjs/plugin/objectSupport";
import { TimeWeekDefinition } from "@/store/Classes/ClassesInterfases";
import ClassModal from "./ClassModal.vue";

dayjs.extend(IsoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

export default defineComponent({
  name: "WeekSchedule",
  data() {
    return {
      day: "",
      hour: "",
      active: "",
      link: "",
      classType: "",
      classId: "",
      openModal: false,
      editPermision: false,
      timeout: null as any,
      interval: null as any,
      minutes: dayjs(),
      permitRoles: [
        "master",
        "academyManager",
        "gtrSystemManager",
        "scheduleManager",
      ],
    };
  },
  props: {
    scheduleId: { type: String, required: true },
    title: { type: String, required: true },
    titleIcon: { type: String },
    scheduleType: { required: true, type: String },
  },
  components: { ClassCard, ClassModal },
  methods: {
    getClassDate(day: number, hourString: string) {
      const parts = hourString.split(":");
      const minutes = Number(parts[1].substring(0, 2));
      const mod = parts[1].substring(2, 4);
      let hour = Number(parts[0]);
      hour = mod == "pm" && hour != 12 ? hour + 12 : hour;
      const date = dayjs().isoWeekday(day).hour(hour).minute(minutes).second(0);

      const dateString = date.format("YYYY-MM-DD HH:mm:ss");
      const localizedDate = dayjs.tz(dateString, "America/Mexico_city");
      return localizedDate;
    },
    getLocalizedHour(hourString: string) {
      const parts = hourString.split(":");
      const minutes = Number(parts[1].substring(0, 2));
      const mod = parts[1].substring(2, 4);
      let hour = Number(parts[0]);
      hour = mod == "pm" && hour != 12 ? hour + 12 : hour;
      const date = dayjs().hour(hour).minute(minutes).second(0);

      const dateString = date.format("YYYY-MM-DD HH:mm:ss");
      const localizedDate = dayjs.tz(dateString, "America/Mexico_city");
      const localizedHourString = localizedDate
        .tz(dayjs.tz.guess())
        .format("h:mm a")
        .toString();
      return localizedHourString;
    },
    changeEditPermission() {
      if (!this.getUserRole.some((r) => this.permitRoles.includes(r))) return;
      this.editPermision = !this.editPermision;
    },
    isNow(hour, next = null as null | string) {
      const today = dayjs();
      hour = hour.toLowerCase().replace("am", " am").replace("pm", " pm");
      const date = dayjs(`${today.format("YYYY-MM-DD")} ${hour}`);
      const diff = today.diff(date, "minutes");
      let multiply = 60;
      if (date.minute() > 0 && date.minute() <= 60) multiply = date.minute();

      if (next) {
        next = next[0].toLowerCase().replace("am", " am").replace("pm", " pm");
        const nextDate = dayjs(`${today.format("YYYY-MM-DD")} ${next}`);
        const nextDiff = nextDate.diff(date, "minutes");
        if (nextDiff <= 60) multiply = nextDiff;
      }

      if (diff > multiply || diff < 0) return null;
      return (diff * 100) / multiply;
    },
  },
  computed: {
    ...mapGetters("classes", ["getSchedule"]),
    ...mapGetters("sys", ["getTelegramBotUsername"]),
    ...mapGetters("auth", ["getUserRole"]),
    hours() {
      const hours: Array<string> = [];
      for (let hour = 0; hour < 24; hour++) {
        hours.push(dayjs({ hour }).format("hh:mm A"));
        hours.push(
          dayjs({
            hour,
            minute: 30,
          }).format("hh:mm A")
        );
      }
      return hours;
    },
    hasEditRole() {
      return this.getUserRole.some((r) => this.permitRoles.includes(r));
    },
    schedule(): Map<string, TimeWeekDefinition> | Array<string> {
      // CREATE AND OBJECT WITH ALL WEEK DAYS
      const weekDays = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };
      // GETTING SCHEDULE INFO
      let schedule = this.getSchedule(this.scheduleId);
      // ITERATE SCHEDULE INFO
      schedule = [...schedule].map((obj) => {
        // CREATE OBJECT WITH ALL WEEK DAYS AND REPLACE CURRENT INFO
        obj[1] = { ...obj[1] };
        Object.entries(obj[1]).map((o) => {
          obj[1][o[0]] = [...(o[1] as [])];
          if (!this.editPermision)
            obj[1][o[0]] = obj[1][o[0]].filter(
              (ele: { active: boolean }) => ele.active
            );

          obj[1][o[0]] = obj[1][o[0]].sort(
            (first: { position: number }, second: { position: number }) =>
              first.position - second.position
          );
        });
        obj[1] = { ...weekDays, ...obj[1] };
        return obj;
      });
      schedule = schedule.filter((obj) => {
        const count = Object.entries(obj[1]).filter((obj) => {
          const array = obj[1] as Array<object>;
          return array.length > 0;
        });
        return count.length > 0;
      });
      return schedule;
    },
  },
  mounted() {
    const seconds = 60 - dayjs().second();
    this.timeout = setTimeout(() => {
      this.minutes = dayjs();
      this.interval = setInterval(() => {
        this.minutes = dayjs();
      }, 60 * 1000);
    }, seconds * 1000);
  },
  unmounted() {
    if (this.timeout) clearInterval(this.timeout);
    if (this.interval) clearInterval(this.interval);
  },
});
