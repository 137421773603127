
import { defineComponent } from "vue";
import { Ticket } from "@/components/tickets/functions";
import TicketTemplate from "@/components/tickets/template.vue";
import { tools } from "@/mixins/tools";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    TicketTemplate,
  },
  name: "ListItems",
  props: ["ticket", "event"],
  computed: {
    date() {
      return (date) => {
        if (date._seconds) date = date._seconds;
        return dayjs(new Date(date * 1000))
          .format("DD MMMM, YYYY | hh:mm A")
          .split(" | ");
      };
    },
    ticketModalId() {
      return (
        this.ticket.name.replace(/[^a-zA-Z]/g, "") +
        "-" +
        tools.methods.generateShortGUID()
      );
    },
  },
  watch: {
    viewTicket() {
      console.log(this.viewTicket);
    },
  },
  data() {
    return {
      showCollapse: false,
      viewTicket: null as null | Ticket,
    };
  },
  methods: {
    handleShowCollapse() {
      this.showCollapse = !this.showCollapse;
    },
    setViewTicket(ticket) {
      this.viewTicket = ticket as Ticket;
    },
  },
});
