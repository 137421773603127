
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { UnilevelMember } from "../../store/Unilevel/UnilevelInterfaces";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import TicketTemplate from "./template.vue";
import firebase from "firebase/compat/app";
import TeamMember from "../unilevel/TeamMember.vue";

export default defineComponent({
  name: "TicketHeader",
  props: ["ticketsInfo", "home", "username", "event"],
  components: {
    VueQrcode,
    TicketTemplate,
    TeamMember,
  },
  data() {
    return {
      ticketsInfoInit: this.ticketsInfo,
      QRoptions: {
        maskPattern: 7,
        scale: 10,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
        margin: 0,
      },
      selected: null as null | UnilevelMember,
      selectedError: null,
      selectedOption: "",
    };
  },
  computed: {
    ...mapGetters("auth", {
      getUsernamePK: "getUsernamePK",
    }),
    pendingTickets() {
      if (this.ticketsInfoInit?.count?.availableTickets)
        return this.ticketsInfoInit?.count?.availableTickets;
      return 0;
    },
    noPaidTickets() {
      if (this.ticketsInfoInit?.count?.noPaidTickets)
        return this.ticketsInfoInit?.count?.noPaidTickets;
      return 0;
    },
    ownTicket() {
      if (
        this.ticketsInfoInit?.ownTicket &&
        this.ticketsInfoInit?.ownTicket.length > 0
      )
        return this.ticketsInfoInit?.ownTicket[0];
      return null;
    },
    options() {
      if (this.ticketsInfoInit?.count?.availableOptions)
        return this.ticketsInfoInit?.count?.availableOptions;
      return [];
    },
  },
  watch: {
    ticketsInfo() {
      this.ticketsInfoInit = this.ticketsInfo;
      console.log("ticketsInfo", this.ticketsInfo);
    },
  },
  methods: {
    async getUser() {
      const searchUserFunction = firebase
        .functions()
        .httpsCallable("Users-searchUser");

      const _user = await searchUserFunction({ username: this.getUsernamePK });
      const user = await _user.data;

      if (user.OPCODE == "SUCCESS") this.selected = user.data as UnilevelMember;
      else this.selected = null;
    },
    async setTicket() {
      const updateTicket = firebase
        .functions()
        .httpsCallable("Tickets-updateTickets");

      const tickets = await updateTicket({
        ownTicket: true,
        option: this.selectedOption,
      });
      this.ticketsInfoInit = tickets.data;
    },
  },
  mounted() {
    console.log("header", this.event);
    this.getUser();
  },
});
