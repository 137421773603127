
import { defineComponent } from "vue";
import TeamMember from "@/components/unilevel/TeamMember.vue";
import TicketHeader from "@/components/tickets/header.vue";
import {
  TicketResponse,
  Ticket,
  TransferedTo,
} from "@/components/tickets/functions";
import firebase from "firebase/compat/app";
import { UnilevelMember } from "functions/src/Unilevel/UnilevelInterfaces";
import ListItems from "@/components/tickets/listItems.vue";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import LoaderSpinner from "@/components/generals/Loader.vue";

export default defineComponent({
  components: {
    TeamMember,
    TicketHeader,
    ListItems,
    LoaderSpinner,
  },
  name: "TicketPacks",
  data() {
    return {
      loading: false,
      selected: null as any,
      selectedTeamSide: null as any,
      buttonDisabled: true,
      team: [] as Array<any>,
      leftTeam: "",
      rightTeam: "",
      ownTicket: null as Ticket | null,
      viewTicket: null as Ticket | null,
      tickets: [] as Array<boolean | undefined | Ticket>,
      pendingTickets: [] as Array<boolean | undefined | Ticket>,
      // options: [] as Array<boolean | undefined | Option>,
      QROptions: {
        maskPattern: 7,
        scale: 10,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
        margin: 0,
      },
      qrValue: "12r64643675746745asfas",
      transferableTickets: 0,
      guest: false,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      selectedOption: "",
      selectedError: "",
      searchUserValue: "",
      reload: false,
      ticketQuantity: 1,
      ticketsInfo: {} as TicketResponse,
      event: null as any,
    };
  },
  computed: {
    ...mapGetters("events", ["getActiveEvents", "getEvent"]),
    ...mapState("events", {
      events: "archivedEvents",
      loading: "archivedEventsLoading",
      loaded: "archivedEventsLoaded",
    }),
    // ...mapGetters("account", ["getAccountSummary"]),
    // accountSummary(): AccountSummary {
    //   return this.getAccountSummary;
    // },
    // ...mapGetters("auth", {
    //   getUsernamePK: "getUsernamePK",
    // }),
    paymentQuote() {
      return {
        subscriptionId: "tickets",
        username: "maph01",
        email: "maph01@gtr.academy",
        duration: 10,
        durationUnit: "s",
        status: "new",
        createdDate: new Date(),
        concept: "tickets",
        exceptionStatus: false,
        subscriptionActivated: true,
      };
    },
    _pendingTickets() {
      if (this.ticketsInfo?.count?.availableTickets)
        return this.ticketsInfo?.count?.availableTickets;
      return 0;
    },
    noPaidTickets() {
      if (this.ticketsInfo?.count?.noPaidTickets)
        return this.ticketsInfo?.count?.noPaidTickets;
      return 0;
    },
    transferedTickets() {
      return Object.entries(this.ticketsInfo.transferedTo ?? {}).map((obj) => {
        const [option, val] = obj as [option: string, val: Array<TransferedTo>];
        return { ...val, option };
      });
    },
    options() {
      if (this.ticketsInfo?.count?.availableOptions)
        return this.ticketsInfo?.count?.availableOptions;
      return [];
    },
    eventActive() {
      console.log(
        !!this.event,
        (this.event?.payment_options &&
          this.event?.payment_options?.length > 0) ||
          this.event?.accept_bitcoin,
        this.event?.ticket_options,
        this.event?.ticket_options?.length > 0
      );
      return (
        !!this.event &&
        ((this.event?.payment_options &&
          this.event?.payment_options?.length > 0) ||
          this.event?.accept_bitcoin) &&
        this.event?.ticket_options &&
        this.event?.ticket_options?.length > 0
      );
    },
  },
  watch: {
    first_name() {
      this.validate();
    },
    last_name() {
      this.validate();
    },
    email() {
      this.validate();
    },
    phone() {
      this.validate();
    },
    guest() {
      this.validate();
    },
    selectedOption() {
      const options = this.options.find((ele) => ele.id == this.selectedOption);
      if (options && this.ticketQuantity >= options?.count)
        this.ticketQuantity = options?.count;
      this.validate();
    },
    selected() {
      this.validate();
    },
    ticketQuantity() {
      const options = this.options.find((ele) => ele.id == this.selectedOption);
      if (options && this.ticketQuantity >= options?.count)
        this.ticketQuantity = options?.count;

      this.validate();
    },
  },
  methods: {
    goBuyTickets() {
      this.$router.push("buy-tickets");
    },
    validate() {
      if (this.guest) {
        if (
          this.first_name.length > 0 &&
          this.last_name.length > 0 &&
          this.email.length > 0 &&
          this.phone.length > 0 &&
          this.selectedOption.length > 0
        )
          this.buttonDisabled = false;
        else this.buttonDisabled = true;
      } else {
        if (this.selectedOption.length > 0 && this.ticketQuantity > 0)
          this.buttonDisabled = false;
        else this.buttonDisabled = true;
      }
    },
    removeSelected() {
      if (this.guest) {
        this.email = "";
        this.phone = "";
        this.last_name = "";
        this.first_name = "";
      } else {
        this.leftTeam = "";
        this.rightTeam = "";
      }

      this.selectedOption = "";
      this.selected = null;
      this.buttonDisabled = true;
      this.searchUserValue = "";
      this.ticketQuantity = 1;
    },
    allTickets() {
      return this.tickets.sort((a: any, b: any) => {
        a = new Date(a.date);
        b = new Date(b.date);
        return b - a;
      }) as Array<Ticket>;
    },
    setTicket() {
      const updateTicket = firebase
        .functions()
        .httpsCallable("Tickets-updateTickets");
      const username =
        this.selected && typeof this.selected == "object"
          ? this.selected.usernamePK
          : null;

      updateTicket({
        option: this.selectedOption,
        username,
        guest: this.guest,
        email: this.email,
        phone: this.phone,
        last_name: this.last_name,
        first_name: this.first_name,
        count: this.ticketQuantity,
      })
        .then(() => this.getTickets())
        .catch((err) => console.log(err))
        .finally(() => this.removeSelected());
    },
    searchUser() {
      const searchUserFunction = firebase
        .functions()
        .httpsCallable("Tickets-SearchUser");

      searchUserFunction({ username: this.searchUserValue })
        .then((res) => res.data)
        .then((res) => {
          if (res.OPCODE == "SUCCESS") {
            this.selected = res.data as UnilevelMember;
            if (this.selectedError.length > 0) this.selectedError = "";
          } else if (res.OPCODE == "ERROR") {
            this.selectedError = res.message;
            if (this.selected) this.selected = null;
          }
        })
        .catch((error) => {
          console.error(error);
          alert(
            "Error al llamar la función de búsqueda de usuarios: " +
              error.message
          );
          // this.searching = false;
        });
      // const user = [...this.unilevelTeam.rightTeam, ...this.unilevelTeam.leftTeam].filter(ele => ele.leader.username.search(evt.target.value) >= 0)
      // this.team = user
    },
    async getTickets() {
      const ticketsPerUser = firebase
        .functions()
        .httpsCallable("Tickets-TicketsPerUser");

      const _ticketsRef = await ticketsPerUser({});
      this.ticketsInfo = _ticketsRef.data;
    },
    async getEvents() {
      return this.$store
        .dispatch("events/doGetActiveEvents")
        .then(async (activeEvents) => {
          const event = activeEvents[0];
          if (event) {
            await this.$store.dispatch("events/doGetEventTicketOptions", {
              event_id: event.id,
            });

            await this.$store.dispatch(
              "events/doGetEventTicketPaymentOptions",
              {
                event_id: event.id,
              }
            );

            this.event = _.cloneDeep(this.getEvent(event.id));
          }
        });
    },
    setGuest() {
      this.selectedError = "";
      this.searchUserValue = "";
      this.guest = !this.guest;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getEvents();
    await this.getTickets();
    this.loading = false;
  },
});
