
import "dayjs/locale/es";
import _ from "lodash";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Event, OptionEvent } from "@/store/Events/EventsInterfaces";
import ModifyTicketOption from "@/components/events/admin/ticket-options/ModifyTicketOption.vue";
import NewTicketOption from "@/components/events/admin/ticket-options/NewTicketOption.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "event-ticket-options",
  props: ["event"],
  components: {
    ModifyTicketOption,
    NewTicketOption,
  },
  data() {
    return {
      currentEvent: null as null | Event,
      ticket_options: [] as OptionEvent[],
    };
  },
  watch: {
    event: function () {
      this.currentEvent = _.clone(this.event);
      console.log("currentEvent");
      this.processTicketOptions();
    },
  },
  computed: {
    ...mapGetters("events", ["getEvent"]),
    date() {
      return (date) => date.format(`DD MMMM, YYYY • hh:mm a`);
    },
    format_currency() {
      return (price, total: number | null = null) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: price.currency,
        }).format(total != null ? total : price.value);
    },
    sales_ends() {
      return (option) => {
        if (option.ticket_sales_ends.type == "fix_date")
          return dayjs(new Date(option.ticket_sales_ends.value.seconds * 1000))
            .locale("es")
            .format(`DD MMMM, YYYY • hh:mm a`);

        const starts = new Date(this.event.date.start._seconds * 1000);
        let day = dayjs(starts);
        let action = "add";

        switch (option.ticket_sales_ends.type) {
          case "after_ends":
          case "before_ends":
            day = dayjs(starts);
            break;
          default:
            day = dayjs(starts);
            break;
        }

        switch (true) {
          case /after/.test(option.ticket_sales_ends.type):
            action = "add";
            break;
          case /before/.test(option.ticket_sales_ends.type):
            action = "subtract";
            break;
        }

        day = day[action](
          option.ticket_sales_ends.value,
          option.ticket_sales_ends.unit
        );
        return day.locale("es").format(`DD MMMM, YYYY • hh:mm a`);
      };
    },
    sales_starts() {
      return (option) => {
        if (
          !option.ticket_sales_starts ||
          option.ticket_sales_starts.type == "immediately"
        )
          return "Inmediatamente";

        const day = dayjs(
          new Date(option.ticket_sales_starts.value.seconds * 1000)
        );
        return day.locale("es").format(`DD MMMM, YYYY • hh:mm a`);
      };
    },
  },
  methods: {
    async getPackOption(obj) {
      if (obj.constructor.name == "DocumentReference") {
        return await this.$store.dispatch("events/getTicketOption", {
          path: obj.path,
        });
      }

      return null;
    },
    async processTicketOptions() {
      const options = _.cloneDeep(this.event.ticket_options ?? []);

      const ticket_options = await Promise.allSettled(
        options.map(async (opt) => {
          let option: OptionEvent | null = null;
          if (
            opt.pack &&
            opt.pack?.option_id?.constructor?.name == "DocumentReference"
          )
            option = await this.getPackOption(opt.pack.option_id);

          if (option) opt.pack.option = option;
          return opt;
        })
      );

      this.ticket_options = ticket_options.map((ele) => ele.value);
    },
    async removeTicketOption(option) {
      if (confirm("¿Estás seguro de eliminar esta opción de ticket?")) {
        this.$store
          .dispatch("events/deleteTicketOption", {
            event_id: this.event.id,
            ticket_id: option.id,
          })
          .then((res) => res.data)
          .then((res) => {
            if (res === true) (this.$parent as any).getData();
          });
      }
    },
  },
  async mounted() {
    this.currentEvent = _.cloneDeep(this.event);
    this.processTicketOptions();
  },
});
