import {
  AccountSummary,
  AccountSummaryState,
  INFO_ACCOUNT_SUMMARY,
  UPDATE_PROMOTIONS,
} from "./AccountInterface";
import { ActionContext } from "vuex";
import firebase from "firebase/compat/app";
import * as fb from "../../firebase";
import dayjs from "dayjs";
import { UnilevelMember } from "../Unilevel/UnilevelInterfaces";
import { NetworkDataScholarship } from "functions/src/Users/UsersInterfaces";
import { Timestamp } from "firebase/firestore";

const initialState: AccountSummaryState = {
  account: {
    academyDaysLeft: 0,
    distributorDaysLeft: 0,
    directAffiliates: 0,
    hasBinaryNetworkBonus: false,
    bonusRemainingDays: 0,
    configuredWallet: false,
    binaryBonusExpiringSide: undefined,
    binarySide: undefined,
    binaryPercentage: 0,
    binaryExpiration: {
      left: {
        endDate: undefined,
        daysLeft: 0,
      },
      right: {
        endDate: undefined,
        daysLeft: 0,
      },
    },
    promotions: {
      tradingAcademy: 0,
      distributorLicense: 0,
      both: 0,
    },
    innerLegUnlocked: undefined,
    scholarshipPoints: 0,
    scholarshipStartDate: undefined,
    scholarshipEndDate: undefined,
    unilevelData: new UnilevelMember(
      "",
      "",
      "",
      "",
      "",
      new Map<string, any>()
    ),
  },
};

const state: AccountSummaryState = { ...initialState };

const mutations = {
  INFO_ACCOUNT_SUMMARY(state: AccountSummaryState, summary: AccountSummary) {
    if (state == undefined) {
      state = { ...initialState };
    }
    state.account = summary;
  },
  UPDATE_PROMOTIONS(state: AccountSummaryState, promotions: any) {
    if (state.account.promotions) {
      state.account.promotions = promotions;
    }
  },
  CLEAR_STATE() {
    Object.assign(state, initialState);
  },
};

const actions = {
  async initializeAccountSummary(
    context: ActionContext<AccountSummaryState, any>,
    username: string
  ) {
    const accountResponse: AccountSummary = {
      academyDaysLeft: 0,
      distributorDaysLeft: 0,
      directAffiliates: 0,
      hasBinaryNetworkBonus: false,
      bonusRemainingDays: 0,
      configuredWallet: false,
      binaryBonusExpiringSide: undefined,
      binarySide: undefined,
      binaryPercentage: 0,
      binaryExpiration: {
        left: {
          endDate: undefined,
          daysLeft: 0,
        },
        right: {
          endDate: undefined,
          daysLeft: 0,
        },
      },
      promotions: {
        tradingAcademy: 0,
        distributorLicense: 0,
        both: 0,
      },
      innerLegUnlocked: undefined,
      scholarshipPoints: 0,
      scholarshipStartDate: undefined,
      scholarshipEndDate: undefined,
      unilevelData: new UnilevelMember(
        "",
        "",
        "",
        "",
        "",
        new Map<string, any>()
      ),
    };
    const _usernamePK = username.toLowerCase();
    const _user = fb.fbfs.doc("users/" + _usernamePK);
    const _distributorLicense = _user
      .collection("subscriptions")
      .doc("distributor-license");
    const _tradingAcademy = _user
      .collection("subscriptions")
      .doc("trading-academy");
    const _networkData = _user.collection("networkData");
    const _directAffiliates = _networkData.doc("directAffiliates");
    const _networkBasic = _networkData.doc("basic");
    const _networkScholarship = _networkData.doc("scholarship");
    const _financial = _user.collection("profile").doc("financial");
    const today = new Date().valueOf();

    const distributorPromise = _distributorLicense
      .get()
      .then((response) => {
        const subsInfo = response.data();
        if (
          subsInfo?.endDate != undefined &&
          subsInfo?.startDate != undefined
        ) {
          accountResponse.distributorDaysLeft = subsInfo.left
            ? Math.floor(
                dayjs(subsInfo.left.endDate.toDate()).diff(today, "day", true)
              )
            : 0;
        } else {
          accountResponse.distributorDaysLeft = 0;
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    const academyPromise = _tradingAcademy
      .get()
      .then((response) => {
        const subsInfo = response.data();
        if (
          subsInfo?.endDate != undefined &&
          subsInfo?.startDate != undefined
        ) {
          accountResponse.academyDaysLeft = subsInfo.left
            ? Math.floor(
                dayjs(subsInfo.left.endDate.toDate()).diff(today, "day", true)
              )
            : 0;
          accountResponse.scholarshipStartDate = subsInfo.endDate.toDate();
        } else {
          accountResponse.academyDaysLeft = 0;
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    const bonusPromise = await Promise.all([distributorPromise, academyPromise])
      .then(() => {
        _networkBasic
          .get()
          .then((response) => {
            const basicInfo = response.data();
            const expirationDts = basicInfo?.binaryExpiration;

            accountResponse.innerLegUnlocked = basicInfo?.innerLegUnlocked;
            accountResponse.binarySide = basicInfo?.binarySide;
            accountResponse.binaryPercentage = basicInfo?.binaryPercentage;

            if (expirationDts != undefined) {
              const leftDays = expirationDts.left
                ? Math.floor(
                    dayjs(expirationDts.left.endDate.toDate()).diff(
                      today,
                      "day",
                      true
                    )
                  )
                : 0;
              const rightDays = expirationDts.right
                ? Math.floor(
                    dayjs(expirationDts.right.endDate.toDate()).diff(
                      today,
                      "day",
                      true
                    )
                  )
                : 0;

              // If both are positive, then the bonus is active
              if (leftDays >= 0 && rightDays >= 0) {
                if (leftDays >= rightDays) {
                  accountResponse.hasBinaryNetworkBonus = true;
                  accountResponse.bonusRemainingDays = rightDays;
                  accountResponse.binaryBonusExpiringSide = "right";
                } else {
                  accountResponse.hasBinaryNetworkBonus = true;
                  accountResponse.bonusRemainingDays = leftDays;
                  accountResponse.binaryBonusExpiringSide = "left";
                }
              }
              accountResponse.binaryExpiration.left.endDate =
                expirationDts.left?.endDate.toDate() ?? undefined;
              accountResponse.binaryExpiration.right.endDate =
                expirationDts.right?.endDate.toDate() ?? undefined;

              accountResponse.binaryExpiration.left.daysLeft = leftDays;
              accountResponse.binaryExpiration.right.daysLeft = rightDays;
              return Promise.resolve();
            } else {
              return Promise.resolve();
            }
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    const affiliatesPromise = _directAffiliates
      .get()
      .then((response) => {
        const affiliates = response.data();
        accountResponse.directAffiliates = affiliates?.directsCount;
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    const getLeaderData = fb.func.httpsCallable("Unilevel-getLeaderData");
    const unilevelData = getLeaderData({
      username: _usernamePK,
    }).then((result) => {
      if (result.data.OPCODE == "SUCCESS") {
        const memberData = result.data.data;
        for (const rankData in memberData.ranksCarrerData) {
          if (memberData.ranksCarrerData[rankData].date) {
            const timestamp = new firebase.firestore.Timestamp(
              memberData.ranksCarrerData[rankData].date._seconds,
              memberData.ranksCarrerData[rankData].date._nanoseconds
            );
            memberData.ranksCarrerData[rankData].date = timestamp;
          }
        }
        memberData.subscriptions = new Map(
          Object.entries(memberData.subscriptions)
        );

        if (memberData.scholarshipData != undefined) {
          memberData.scholarshipData.periodEndDate =
            new firebase.firestore.Timestamp(
              memberData.scholarshipData.periodEndDate._seconds,
              memberData.scholarshipData.periodEndDate._nanoseconds
            ).toDate();
        }
        accountResponse.unilevelData = memberData as UnilevelMember;
        return Promise.resolve();
      } else {
        return Promise.reject(result.data);
      }
    });
    const financialPromise = _financial
      .get()
      .then((response) => {
        const financial = response.data();
        if (financial?.btcWallet && financial?.btcWallet.address.trim() != "") {
          accountResponse.configuredWallet = true;
        } else {
          accountResponse.configuredWallet = false;
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    const scholarshipPromise = _networkScholarship
      .get()
      .then((response) => {
        let scholarship: NetworkDataScholarship =
          response.data() as NetworkDataScholarship;
        if (scholarship) {
          if (
            (scholarship.periodEndDate as Timestamp).toDate() < new Date() &&
            scholarship.nextPeriods.length > 0
          ) {
            accountResponse.scholarshipPoints = -100;
            // Update scholarship data
            const UpdateUserScholarshipPeriod = fb.func.httpsCallable(
              "Subscriptions-UpdateUserScholarshipPeriod"
            );
            UpdateUserScholarshipPeriod({
              username: _usernamePK,
            })
              .then(async (result) => {
                if (result.data.OPCODE == "SUCCESS") {
                  // Get new scholarship data
                  scholarship = await _networkScholarship
                    .get()
                    .then((response) => {
                      return response.data() as NetworkDataScholarship;
                    })
                    .catch((error) => {
                      return Promise.reject(error);
                    });
                } else {
                  console.log(result.data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          accountResponse.scholarshipPoints = scholarship.periodPoints;
          accountResponse.scholarshipEndDate = (
            scholarship.periodEndDate as Timestamp
          ).toDate();
          accountResponse.scholarshipStartDate = (
            scholarship.periodStartDate as Timestamp
          ).toDate();
          if (
            (scholarship.periodStartDate as Timestamp).toDate() > new Date()
          ) {
            accountResponse.scholarshipPoints = -100;
          }
        } else {
          accountResponse.scholarshipPoints = -100;
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    const promotionsPromise = _networkData
      .doc("promotions")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const promotions: any = doc.data();
          accountResponse.promotions.tradingAcademy =
            promotions["trading-academy"] || 0;
          accountResponse.promotions.distributorLicense =
            promotions["distributor-license"] || 0;
          accountResponse.promotions.both = promotions["both"] || 0;
          context.commit(UPDATE_PROMOTIONS, accountResponse.promotions);
        }
      });

    return await Promise.all([
      distributorPromise,
      academyPromise,
      bonusPromise,
      unilevelData,
      affiliatesPromise,
      financialPromise,
      promotionsPromise,
      scholarshipPromise,
    ])
      .then(() => {
        context.commit(INFO_ACCOUNT_SUMMARY, accountResponse);
        return Promise.resolve();
      })
      .catch((error) => {
        return {
          OPCODE: "ERROR",
          message: error.message,
          errorDetail: error,
        };
      });
  },
};

const getters = {
  getAccountSummary(state: AccountSummaryState): AccountSummary {
    return state.account;
  },
  getPromotions(state: AccountSummaryState) {
    return state?.account.promotions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
