
import {
  QuoteRequest,
  QuoteRequestType,
} from "functions/src/Payments/PaymentsInterfaces";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  subscriptionType,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import * as bs from "bootstrap";

export default defineComponent({
  name: "video-payment-card",
  data() {
    return {
      QuoteRequest: {} as QuoteRequest<QuoteRequestType>,
    };
  },
  props: {
    subscriptionName: { type: String, required: true, default: "Suscripción" },
    subscriptionIcon: { type: String, required: true, default: "fas fa-book" },
    subscriptionId: { type: Object as () => subscriptionType, required: true },
    lastQuote: { type: Object, required: true },
    isQuoting: { type: Boolean, required: true },
  },
  emits: ["isActive", "clearQuote", "requestQuote"],
  computed: {
    ...mapGetters("auth", ["getUsernamePK", "getUseremail"]),
    ...mapGetters("subs", ["getSubscriptionConfig", "getUserSubscription"]),
    ...mapGetters("sys", ["isOffline"]),
    isLoaded(): boolean {
      const loaded =
        this.SubscriptionConfig != undefined && this.UserStatus != undefined;
      return loaded;
    },
    SubscriptionConfig(): SubscriptionInfo {
      return this.getSubscriptionConfig(this.subscriptionId);
    },
    UserStatus(): UserSubscription {
      return this.getUserSubscription(this.subscriptionId);
    },
    isActive(): boolean {
      const status =
        this.UserStatus.endDate != undefined &&
        this.UserStatus.startDate != undefined &&
        this.UserStatus.endDate > new Date();
      this.$emit("isActive", this.subscriptionId, status);
      return status;
    },
    canBuy(): boolean {
      let canBuy = false;
      if (this.subscriptionId === "mmi-colombia") {
        const academy = this.getUserSubscription("trading-academy");
        // const license = this.getUserSubscription("distributor-license");
        const colombia = this.getSubscriptionConfig("mmi-colombia");
        canBuy =
          academy.isActive &&
          // license.isActive &&
          this.whitelistCheck &&
          !colombia.locked;
      } else {
        canBuy = true;
      }
      return canBuy;
    },
    whitelistCheck(): boolean {
      if (this.subscriptionId === "mmi-colombia") {
        const colombia = this.getSubscriptionConfig("mmi-colombia");
        const wl = colombia.whitelistActive
          ? colombia.whitelist.includes(this.getUsernamePK)
          : true;
        return wl;
      } else {
        return true;
      }
    },
    isLocked(): boolean {
      if (this.subscriptionId === "mmi-colombia") {
        const colombia = this.getSubscriptionConfig("mmi-colombia");
        return colombia.locked;
      } else {
        return false;
      }
    },
  },
  methods: {
    subscriptionQuote() {
      const fiveMinutes = new Date();
      fiveMinutes.setTime(fiveMinutes.getTime() + 5 * 60 * 1000); //5 minutes from now
      if (
        !(
          this.lastQuote.subscriptionId == this.subscriptionId &&
          this.lastQuote.username == this.getUsernamePK &&
          this.lastQuote.expirationTime > fiveMinutes
        )
      ) {
        this.$emit("clearQuote");
        const request: QuoteSubscriptionRequest = {
          subscriptionId: this.subscriptionId,
          duration: this.SubscriptionConfig.duration,
          durationUnit: this.SubscriptionConfig.durationUnit,
          username: this.getUsernamePK,
          email: this.getUseremail,
          isDirectAffiliate: false,
        };
        this.$emit("requestQuote", request);
      }
    },
  },
  mounted() {
    if (this.$refs.productLocked) {
      new bs.Popover(this.$refs.productLocked);
    }
  },
});
