// Actions
export const doGetClasses = "doGetClasses";
export const doGetSchedules = "doGetSchedules";

// Mutations
export const SET_CLASSES = "SET_CLASSES";
export const SET_SCHEDULE_ACADEMY = "SET_SCHEDULE_ACADEMY";
export const SET_SCHEDULE_GTR = "SET_SCHEDULE_GTR";

export interface ClassesState {
  academy: Map<string, TimeWeekDefinition>;
  gtrSystem: Map<string, TimeWeekDefinition>;
}

export interface TimeWeekDefinition {
  monday?: ClassData[];
  tuesday?: ClassData[];
  wednesday?: ClassData[];
  thursday?: ClassData[];
  friday?: ClassData[];
  saturday?: ClassData[];
  sunday?: ClassData[];
}

export class EventCardParameters implements EventCardParametersBase {
  title: string;
  link: string;
  imageUrl?: string;
  registrationOpen?: boolean;
  requiredRank: string;
  constructor(title: string, link: string, requiredRank?: string) {
    this.title = title;
    this.link = link;
    this.requiredRank = requiredRank ? requiredRank : "INITIAL";
  }
}

export interface EventCardParametersBase {
  title?: string;
  link?: string;
  imageUrl?: string;
  registrationOpen?: boolean;
  requiredRank?: string;
  position?: number;
}

export function classFactory(
  classType: string,
  params: EventCardParametersBase
) {
  if (classType.search("-") >= 0) classType = classType.replace(/-[0-9]+/, "");
  switch (classType) {
    case "BinaryOptionsClass":
      return new BinaryOptionsClass(params);
    case "ForexClass":
      return new ForexClass(params);
    case "SportsTradingClass":
      return new SportsTradingClass(params);
    case "MarketkingClass":
      return new MarketkingClass(params);
    case "CryptospaceClass":
      return new CryptospaceClass(params);
    case "EnglishClass":
      return new EnglishClass(params);
    case "AIMoneyClass":
      return new AIMoneyClass(params);
    case "WakeupCall":
      return new WakeupCall(params);
    case "LlamadaTestimoniosCall":
      return new LlamadaTestimoniosCall(params);
    case "BusinessPlan":
      return new BusinessPlan(params);
    case "Mentorship":
      return new Mentorship(params);
    case "AdvancedMentorship":
      return new AdvancedMentorship(params);
    case "FastStartCall":
      return new FastStartCall(params);
    case "MindsetCall":
      return new MindsetCall(params);
    case "FreeClass":
      return new FreeClass(params);
    case "FHH":
      return new FromHumanToHero(params);
    case "GTRWoman":
      return new GTRWoman(params);
    default:
      return new GenericClass(params);
  }
}

abstract class ClassData implements EventCardParameters {
  requiredRank: string;
  title: string;
  imageUrl: string;
  link: string;
  registrationOpen: boolean;
  constructor(params: EventCardParameters) {
    this.title = params.title;
    this.imageUrl =
      "" +
      (params.imageUrl == undefined ? "default-card.png" : params.imageUrl);
    this.link = params.link;
    this.registrationOpen =
      params.registrationOpen == undefined ? false : params.registrationOpen;
    this.requiredRank = params.requiredRank;
  }
}

export class GenericClass extends ClassData {
  constructor(params: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("Clase", "http://zoom.us/j/0")
        : params;
    params.link = params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title = params.title == undefined ? "Clase" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "default-card.png" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

// Clases en Vivo
export class BinaryOptionsClass extends ClassData {
  constructor(params: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("Opciones Binarias", "http://zoom.us/j/0")
        : params;
    params.link = params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title =
      params.title == undefined ? "Opciones Binarias" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "academy/binarias.jpeg" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class ForexClass extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("Forex", "http://zoom.us/j/0")
        : params;
    params.link = params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title = params.title == undefined ? "Forex" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "academy/forex.jpeg" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class SportsTradingClass extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("Trading Deportivo", "http://zoom.us/j/0")
        : params;
    params.link = params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title =
      params.title == undefined ? "Trading Deportivo" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "academy/deportes.jpeg" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class MarketkingClass extends ClassData {
  constructor(params: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("MarketKing", "http://zoom.us/j/0")
        : params;
    params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title = params.title == undefined ? "MarketKing" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "academy/marketking.jpeg"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class CryptospaceClass extends ClassData {
  constructor(params: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("Criptospace", "http://zoom.us/j/0")
        : params;
    params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title = params.title == undefined ? "Cryptospace" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "academy/cryptospace.jpeg"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class EnglishClass extends ClassData {
  constructor(params: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("English", "http://zoom.us/j/0")
        : params;
    params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title = params.title == undefined ? "English" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "academy/English.jpeg" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class AIMoneyClass extends ClassData {
  constructor(params: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters("AI Money", "http://zoom.us/j/0")
        : params;
    params.link == undefined ? "http://zoom.us/j/0" : params.link;
    params.title = params.title == undefined ? "AI Money" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "academy/ai_money.png" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

// Liderazgo GTR
export class WakeupCall extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Wake Up Call",
            "https://www.vibragtr.com/wakeup"
          )
        : params;
    params.link = "https://www.vibragtr.com/wakeup";
    params.title = params.title == undefined ? "Wake Up Call" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/wakeup_call.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class LlamadaTestimoniosCall extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Llamada de Testimonios",
            "http://www.vibragtr.com/fblive"
          )
        : params;
    params.link = "http://www.vibragtr.com/fblive";
    params.title =
      params.title == undefined ? "Llamada de Testimonios" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/testimonios.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class BusinessPlan extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Plan de Negocios",
            "https://vibragtr.com/Lanuevaeconomia"
          )
        : params;
    params.link = "https://vibragtr.com/Lanuevaeconomia";
    params.title =
      params.title == undefined ? "Plan de Negocios" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/plan_de_negocios.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class Mentorship extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Mentoria",
            "https://vibragtr.com/Mentoriagtr"
          )
        : params;
    params.link = "https://vibragtr.com/Mentoriagtr";
    params.title = params.title == undefined ? "Mentoria" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "liderazgo/mentoria.png" : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class AdvancedMentorship extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Cap Influencers +",
            "https://vibragtr.com/Influencer",
            "INF"
          )
        : params;
    params.link = "https://vibragtr.com/Influencer";
    params.title =
      params.title == undefined ? "Capacitación Influencers & +" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/influencers.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class FastStartCall extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Inicio Rápido",
            "https://vibragtr.com/Mentoriagtr"
          )
        : params;
    params.link = "https://vibragtr.com/Mentoriagtr";
    params.title = params.title == undefined ? "Inicio Rápido" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/inicio_rapido.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}
export class MindsetCall extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Mindset Call",
            "https://vibragtr.com/mindset"
          )
        : params;
    params.link = "https://vibragtr.com/mindset";
    params.title = params.title == undefined ? "Mindset Call" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/mindsetcall.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class FreeClass extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "Clase Gratis",
            "https://vibragtr.com/Clasegratis"
          )
        : params;
    params.link = "https://vibragtr.com/Clasegratis";
    params.title = params.title == undefined ? "Clase Gratis" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/clase_gratis.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class FromHumanToHero extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "From Human to Hero",
            "https://vibragtr.com/FHH"
          )
        : params;
    params.link = "https://vibragtr.com/FHH";
    params.title =
      params.title == undefined ? "From Human to Hero" : params.title;
    params.imageUrl =
      params.imageUrl == undefined ? "liderazgo/fhh.png" : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export class GTRWoman extends ClassData {
  constructor(params?: EventCardParametersBase) {
    params =
      params == undefined
        ? new EventCardParameters(
            "GTR Woman",
            "https://vibragtr.com/GTRWOMAN",
            "WOMAN"
          )
        : params;
    params.link = "https://vibragtr.com/GTRWOMAN";
    params.title = params.title == undefined ? "GTR Woman" : params.title;
    params.imageUrl =
      params.imageUrl == undefined
        ? "liderazgo/gtr_woman.png"
        : params.imageUrl;
    super(params as EventCardParameters);
  }
}

export function sortTime(
  a: [string, TimeWeekDefinition],
  b: [string, TimeWeekDefinition]
) {
  let r = 0;
  let aHour = parseInt(a[0].split(":")[0]);
  let bHour = parseInt(b[0].split(":")[0]);
  aHour = a[0].endsWith("pm") && aHour != 12 ? aHour + 12 : aHour;
  bHour = b[0].endsWith("pm") && bHour != 12 ? bHour + 12 : bHour;
  r = aHour - bHour;
  if (r === 0) {
    const aMins = parseInt(a[0].split(":")[1].substring(0, 2));
    const bMins = parseInt(b[0].split(":")[1].substring(0, 2));
    //console.log(a[0], aMins, b[0], bMins, " ->", r);
    r = aMins - bMins;
  }
  //console.log(a[0], aHour, b[0], bHour, " ->", r);
  return r;
}

export function BuildSchedule(
  querySnapshot: any,
  day: string,
  scheludeMap: Map<string, TimeWeekDefinition>,
  scheduleType: string
) {
  for (const doc of querySnapshot.docs) {
    const h = doc.id;
    const data = doc.data();
    for (const c of Object.keys(data)) {
      if (!scheludeMap.has(h)) {
        scheludeMap.set(h, {} as TimeWeekDefinition);
      }
      const hour = scheludeMap.get(h) || {};
      if (!hour[day as keyof TimeWeekDefinition]) {
        hour[day as keyof TimeWeekDefinition] = [];
      }

      // console.log(data[c], c);

      const $class = {
        id: h,
        active: data[c].active,
        position: data[c].position,
        classType: c,
        scheduleType,
        ...classFactory(c, data[c]),
      };
      hour[day as keyof TimeWeekDefinition]?.push($class);
    }
  }
}
